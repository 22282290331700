<template>
  <div class="SuccessEmail">
    <img
      class="SuccessEmail-LogoImg"
      src="../assets/images/LogoCE306x60.png"
      alt="Logo"
      width="230"
    >
    <h3 class="SuccessEmail-Header">
      {{ $t('reg_success_title') }}
    </h3>
    <p class="SuccessEmail-Text">
      {{ $t('reg_success_text_1') }} {{ email }} {{ $t('reg_success_text_2') }}
    </p>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Success'
  },
  data () {
    return {
      email: ''
    }
  },
  mounted () {
    this.email = this.$route.params.email
  }
}
</script>

<style lang="stylus">
.SuccessEmail
  margin: auto
  max-width: 500px;
  margin-top: 10%
  margin-bottom: 15%
  background: #ffffff
  padding: 60px
  border-radius: 4px
  text-align: center
  box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.2)
  &-LogoImg
    margin-top 20px
  &-Header
    margin-top 30px
    color #5F5F5F
    font-weight: bold;
    font-size: 20px
    font-family 'Montserrat', sans-serif
  &-Text
    font-family 'Geometria', sans-serif
    margin-top 14 px
    margin-bottom: 40px;
@media screen and (max-width: 414px)
  .SuccessEmail
    padding: 50px 30px
    max-width: 92%;
    margin-top: 20%;
    margin-bottom: 30%;
    &-LogoImg
      width: 96%
@media screen and (max-width: 375px)
  .SuccessEmail
    padding: 50px 30px
    max-width: 92%;
    margin-top: 20%;
    margin-bottom: 30%;
    &-LogoImg
      width: 96%
</style>
