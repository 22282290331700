<template>
  <div class="Registration Global_Card ce-registration">
    <router-link
      class="Registration-LoginActiv"
      to="/lk/login"
    >
      {{ $t('login_link') }}
    </router-link>
    <router-link
      class="Registration-RegistrationActiv"
      to="/lk/registration"
    >
      {{ $t('registration_link') }}
    </router-link>
    <h3
      v-if="isProductionHostname"
      class="underway"
    >
      Онлайн регистрация временно недоступна
      <span>для регистрации свяжитесь с нами <a href="mailto:info@cryptoenter.ru">info@cryptoenter.ru</a></span>
    </h3>
    <form
      v-else-if="step"
      class="ce-registration-form"
      @submit.prevent.stop="sendEmail"
    >
      <div class="ce-registration-form-line">
        <ce-text-field
          id="email"
          v-model.trim="email"
          :placeholder="$t('regp_email_placeholder')"
          :label="$t('regp_email_label')"
          type="text"
          name="email"
          @blur="validateEmail"
          @change="validLogin"
        >
          <template #append-input>
            <div class="ce-registration-form-icon">
              <i class="Icon_Info" />
              <div class="Form-HelpModal">
                <div class="help_input_header">
                  {{ $t('regp_email_example_1') }}
                </div>
                <div class="help_input_content">
                  {{ $t('regp_email_example_2') }} <span>name@name.com</span>
                </div>
              </div>
            </div>
          </template>
        </ce-text-field>
      </div>
      <div class="ce-registration-form-line">
        <ce-text-field
          id="promo"
          v-model="promo"
          type="text"
          data-valid="promo"
          name="promo"
          :placeholder="$t('regp_promo_placeholder')"
          :label="$t('regp_promo_label')"
          disabled
        />
      </div>

      <vue-recaptcha
        class="Registration_MarginCommon_Bottom"
        sitekey="6LfCcpwUAAAAAOUI1K1s2S1oWtiA7bgSm8zrWzr6"
        size="compact"
        @verify="recapchaver"
      />
      <div class="Registration-Line general-checkbox">
        <input
          id="general-checkbox"
          class=""
          type="checkbox"
          name="general-checkbox"
          :checked="generalCheckbox"
        >
        <label
          class="Registration-Checkbox"
          for="general-checkbox"
        />
        <p class="general-checkbox_margin">
          {{ $t('regp_general_checkbox_label') }}
        </p>
      </div>
      <div class="Global_InfoText Global_InfoText_Reg">
        <p
          class="Global_Text Global_Text_FontSize_S Global_Text_Color_Standart Registration_FontWidth"
        >
          <!--          CryptoEnter OÜ Terms of Use Introduction This is a contract between you and Cryptoenter OÜ (“The Service”,-->
          <!--          “Cryptoenter OÜ”).-->
          <!--          By signing up to use an account through Cryptoenter.com, APIs, or mobile applications, you agree that you have-->
          <!--          read, understood,-->
          <!--          and accept all of the terms and conditions contained in this Agreement, as well as our Privacy Policy. Please-->
          <!--          read these Terms of Use-->
          <!--          ("Terms", "Terms of Use") carefully before using the https://cryptoenter.com website and the Cryptoenter mobile-->
          <!--          application-->
          <!--          (the "Service") operated by our company. Your access to and use of the Service is conditioned on your acceptance-->
          <!--          of and-->
          <!--          compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.-->
          <!--          If you do not agree to accept and comply with any of the provisions of the Terms of Use, or if the Terms of Use-->
          <!--          are contrary-->
          <!--          to your national law or to the law of your location, you agree not to use the Service, otherwise, you are liable-->
          <!--          for all losses-->
          <!--          and damages to the Service or to third parties, caused by your failure to follow the Terms of Use. In addition,-->
          <!--          when using the-->
          <!--          Cryptoenter OÜ particular services, you shall be subject to any posted guidelines or rules applicable to such-->
          <!--          services,-->
          <!--          which may be posted and modified from time to time. To be eligible to use the Service, you must be at least 18-->
          <!--          years old.-->
          <!--          Changes Occasionally we may, in our discretion, make changes and improvements to the Cryptoenter OÜ Terms of-->
          <!--          Use.-->
          <!--          When we make changes to the Terms that we consider substantial, we’ll notify you through the Service. By-->
          <!--          continuing-->
          <!--          to use the Service after those changes are made, you express and acknowledge your acceptance of the changes.-->
          <!--          Cryptoenter OÜ Account Users get access to the account, interface and some services of the service using the-->
          <!--          user login,-->
          <!--          password and private key, which is generated on the user's side, and are provided when the User registers with-->
          <!--          the service.-->
          <!--          In order to create an Account at the Service, as well as to access the services of Cryptoenter OÜ, the User must-->
          <!--          first register-->
          <!--          at the Service. An account at the Service is a personal page of the User, and may only be used by the User who-->
          <!--          have access to the-->
          <!--          Account through the Service. The User is prohibited to provide his account access information or the account-->
          <!--          itself to third parties-->
          <!--          or to other Users. In the case of theft of the account access information or account itself by a third party,-->
          <!--          the User bears the-->
          <!--          burden of proving he was not responsible. The User is liable for the actions of any third party who was provided-->
          <!--          with access to his-->
          <!--          Account. In order to use certain features of the Service, including certain transfers of Digital Currency, you-->
          <!--          may be required to-->
          <!--          provide the Service with certain personal information, including, but not limited to, your name, address,-->
          <!--          telephone number,-->
          <!--          e-mail address, date of birth and information regarding your bank account. In submitting this or any other-->
          <!--          personal information-->
          <!--          that may be required, you verify that the information is accurate and authentic, and you agree to update the-->
          <!--          Service if any-->
          <!--          information changes. Services Provided The basic functionality that has been implemented in the blockchain-->
          <!--          infrastructure for-->
          <!--          digital banking Cryptoenter OÜ is the transfer of value from one user to another. For the transfer transaction-->
          <!--          the user must-->
          <!--          sign the transfer request by its private key, then Cryptoenter OÜ checks the available balance and if the-->
          <!--          balance allows,-->
          <!--          makes a transfer of a specified amount in the form of received and signed request from the user. Users of the-->
          <!--          blockchain-->
          <!--          infrastructure for digital banking Cryptoenter OÜ can transfer only cryptocurrencies. The following types of-->
          <!--          exchange transactions-->
          <!--          are available in the blockchain infrastructure for digital banking Cryptoenter OÜ: - Exchange with the system - -->
          <!--          Transfers between-->
          <!--          users The implemented exchange procedure allows guaranteed and secure exchange of various values, which are-->
          <!--          recorded in the-->
          <!--          blockchain infrastructure for digital banking Cryptoenter OÜ. To perform an exchange operation, you must specify-->
          <!--          the following data:-->
          <!--          wallet, account, currency, data of the counterparty to choose user id, wallet, account. Currency and amount to-->
          <!--          be exchanged.-->
          <!--          The signed exchange request is recorded in the data stream and sent for confirmation to the counterparty. After-->
          <!--          confirmation of-->
          <!--          the application for exchange by both users, simultaneous transfer of funds under the application for exchange to-->
          <!--          users will be made.-->
          <!--          The exchange of one cryptocurrency for another is available to all users of the blockchain infrastructure for-->
          <!--          digital banking-->
          <!--          Cryptoenter OÜ. Exchange operations of Fiat currencies (USD, EUR, Rub and so on) to cryptocurrencies (Bitcoin,-->
          <!--          Ethereum and so on)-->
          <!--          directly between users are available in the blockchain infrastructure for digital banking Cryptoenter OÜ only-->
          <!--          between users who have-->
          <!--          passed the KYC procedure. The implemented exchange functionality is basic for creating various financial-->
          <!--          instruments within-->
          <!--          The Cryptoenter OÜ payment service. Eligible users in certain jurisdictions may buy or sell supported Digital-->
          <!--          Currency through-->
          <!--          the Conversion Services. The Conversion Services are subject to the conversion rate for the given transaction.-->
          <!--          That conversion-->
          <!--          rate will be displayed on the page of your order with all fees included. After the submitting a request for-->
          <!--          buying Digital Currency, you will need to complete the verification procedures that include verifying you credit-->
          <!--          card information and your phone number. Blockchain-based infrastructure for digital banking Cryptoenter OÜ-->
          <!--          reserves the right to delay any Conversion Service transaction if it perceives a risk of fraud or illegal-->
          <!--          activity. Cryptoenter OÜ may require you to provide or verify additional information, or to wait some amount of-->
          <!--          time after completion of a transaction, before permitting you to use any blockchain-based infrastructure for-->
          <!--          digital banking Cryptoenter OÜ Blockchain-based infrastructure for digital banking and/or before permitting you-->
          <!--          to engage in transactions beyond certain volume limits. The amount that the user receives may differ from-->
          <!--          originally shown due to sharp jumps in rate. In this case, if the amount differs by more than 25 percent, the-->
          <!--          user has the right to request a refund. The Hosted Digital Currency Wallet services are available only in-->
          <!--          connection with those Digital Currencies that Cryptoenter OÜ, in its sole discretion, decides to support. The-->
          <!--          Digital Currencies that Cryptoenter OÜ supports may change from time to time. If you have any questions about-->
          <!--          which Digital Currencies Cryptoenter OÜ currently supports, please write a request to support@Cryptoenter.com.-->
          <!--          Under no circumstances should you attempt to use your Hosted Digital Currency Wallet services to store, send,-->
          <!--          request, or receive digital currencies in any form that are not supported by Cryptoenter OÜ. Cryptoenter OÜ-->
          <!--          assumes no responsibility or liability in connection with any attempt to use blockchain-based infrastructure for-->
          <!--          digital banking Cryptoenter OÜ for digital currencies that Cryptoenter OÜ does not support. Cryptoenter OÜ-->
          <!--          processes supported Digital Currency according to the instructions received from its users. You should verify-->
          <!--          all transaction information prior to submitting instructions to Cryptoenter OÜ. In the event you initiate a-->
          <!--          Digital Currency Transaction by entering the recipient's e-mail address and the recipient does not have an-->
          <!--          existing Cryptoenter OÜ Account, Cryptoenter OÜ will contact the recipient and invite him to open a Cryptoenter-->
          <!--          OÜ Account. Cryptoenter OÜ may charge network fees (miner fees) to process a Digital Currency transaction on-->
          <!--          your behalf. Cryptoenter OÜ will calculate the network fee in its discretion, although Cryptoenter OÜ will-->
          <!--          always notify you of the network fee at or before the time you authorize the transaction. General Use,-->
          <!--          Prohibited Use and Cancellation License We grant you a limited, nonexclusive, nontransferable license, subject-->
          <!--          to the terms of this Agreement, to access and use the Service, and related content, materials, information-->
          <!--          (collectively, the "Content") solely for approved purposes as permitted by Cryptoenter OÜ. Any other use of the-->
          <!--          Service or Content is expressly prohibited and all other right, title, and interest in the Service or Content is-->
          <!--          exclusively the property of Cryptoenter OÜ and its licensors. You agree you will not copy, transmit, distribute,-->
          <!--          sell, license, reverse engineer, modify, publish, or participate in the transfer or sale of, or in any other way-->
          <!--          exploit any of the Content, in whole or in part. "Cryptoenter.com", "Cryptoenter OÜ", and all logos related to-->
          <!--          the Cryptoenter OÜ or displayed on the Cryptoenter.com are either trademarks or registered marks of Cryptoenter-->
          <!--          OÜ or its licensors. You may not copy, imitate or use them without Cryptoenter OÜ prior written consent.-->
          <!--          Although we intend to provide accurate and timely information on the Service, the Service may not always be-->
          <!--          entirely accurate, complete or current and may also include technical inaccuracies or typographical errors. This-->
          <!--          site and its components are offered for informational purposes only; this site shall not be responsible or-->
          <!--          liable for the accuracy, usefulness or availability of any information transmitted or made available via the-->
          <!--          site, and shall not be responsible or liable for any error or omissions in that information. Suspension,-->
          <!--          Termination, and Cancellation. Cryptoenter OÜ may suspend, restrict, or terminate your access to any or all of-->
          <!--          the services, and/or deactivate or cancel your Cryptoenter OÜ Account if we are so required by a facially valid-->
          <!--          subpoena, court order, or binding order of a government authority; or we reasonably suspect you of using your-->
          <!--          Cryptoenter OÜ Account in connection with a Prohibited Use or Business; or use of your Cryptoenter OÜ Account is-->
          <!--          subject to any pending litigation, investigation, or government proceeding and/or we perceive a heightened risk-->
          <!--          of legal or regulatory non-compliance associated with your Account activity; or our service partners are unable-->
          <!--          to support your use; or you take any action that Service deems as circumventing Cryptoenter OÜ's controls,-->
          <!--          including, but not limited to, opening multiple Cryptoenter OÜ Accounts or abusing promotions which Cryptoenter-->
          <!--          OÜ may offer from time to time; or you breach our Behavior Policy. Password Information You are responsible for-->
          <!--          maintaining adequate security and control of any and all IDs, passwords, privet key, personal identification-->
          <!--          numbers (PINs) or any other codes that you use to access Cryptoenter OÜ. Any loss or compromise of the foregoing-->
          <!--          information and/or your personal information may result in unauthorized access to your Cryptoenter OÜ Account by-->
          <!--          third-parties and the loss or theft of any Digital Currency and/or funds held in your Cryptoenter OÜ Account and-->
          <!--          any associated accounts, including your linked bank account(s) and credit card(s). You are responsible for-->
          <!--          keeping your email address and telephone number up to date in your Account Profile in order to receive any-->
          <!--          notices or alerts that we may send you. We assume no responsibility for any loss that you may sustain due to-->
          <!--          compromise of account login credentials due to no fault of Cryptoenter OÜ and/ or failure to follow or act on-->
          <!--          any notices or alerts that we may send to you. In the event you believe your Cryptoenter OÜ Account information-->
          <!--          has been compromised, contact Cryptoenter OÜ Support immediately at support@Cryptoenter.com. Cryptocurrency-->
          <!--          purchase is a high-risk operation. Before investing into any altcoin, it is necessary to analyze all possible-->
          <!--          risks associated with the fall in its value. It is required to take into account that some cryptocurrencies can-->
          <!--          completely devaluate and stop trading on the platform. Cryptoenter OÜ is not responsible for sharp fluctuations-->
          <!--          in cryptocurrency, its fall in value, impairment, delistment from the site and mobile wallets. Cryptoenter OÜ-->
          <!--          draws your attention to the fact, that investment of bitcoins and other altсoins in favor of Forex brokers, CFD,-->
          <!--          binary options, gambling, etc. is prohibited through the platform. The platform analyzes the crypto wallets to-->
          <!--          which the cryptocurrency is sent and can block funds in case of detecting suspicious transactions. Complaints-->
          <!--          and Dispute Resolution If you have any feedback, questions, or complaints, write us an email to-->
          <!--          support@Cryptoenter.com or contact an agent via online chat on our webpage https://cryptoenter.com. In the event-->
          <!--          of a complaint, please set out the cause of your complaint, how you would like us to resolve the complaint and-->
          <!--          any other information you believe to be relevant. A Customer Complaints agent will consider your complaint. The-->
          <!--          Agent will consider your complaint without prejudice based on the information you have provided and any-->
          <!--          information provided by Cryptoenter OÜ. Your complaint will be considered within a reasonable time. An Agent-->
          <!--          will offer to resolve your complaint in the way your requested - you requested; make a determination rejecting-->
          <!--          your complaint and set out the reasons for the rejection; or offer to resolve your complaint with an alternative-->
          <!--          solution. You agree to use the complaints procedure of this Section 6 before filing any claim in a competent-->
          <!--          court. Failure to comply with this provision may be used as evidence of your unwillingness to settle the issue-->
          <!--          and/or the vexatious nature of the complaint. You agree to use the complaints procedure of this Section 6 before-->
          <!--          filing any claim with a bank in case of stolen cards, duplicate processing, refund and similar cases. The user-->
          <!--          has a right for refund if he raises a request within 3 days of purchase. The reason of the request will be-->
          <!--          considered by our specialists within a reasonable time. Failure to comply with this provision may be used as-->
          <!--          evidence of your fraudulent conduct, unwillingness to settle the issue and/or the vexatious nature of the-->
          <!--          complaint General Provisions Cryptoenter OÜ does not offer general or personal financial advice. Nothing in this-->
          <!--          website is intended to be or should be taken as financial, legal or taxation advice. Users should seek their own-->
          <!--          financial, legal, tax and accounting advice as to the likely outcomes for them of trading Digital Currency. We-->
          <!--          shall not bear any liability, whatsoever, for any damage or interruptions caused by any computer viruses,-->
          <!--          spyware, scareware, Trojan horses, worms or other malware that may affect your computer or other equipment, or-->
          <!--          any phishing, spoofing or other attack. We advise the regular use of a reputable and readily available virus-->
          <!--          screening and prevention software. Although the Cryptoenter OÜ takes all necessary and reasonable measures to-->
          <!--          keep the Service up and working, Using the Service users do so at their own risk. The Service is provided-->
          <!--          without any guarantees, representations and warranties, express or implied, provided by law with respect to-->
          <!--          software or sites, including, but not limited to warranties or conditions of quality, performance,-->
          <!--          non-infringement, merchantability, and fitness for use for a particular purpose. The Service does not represent-->
          <!--          or warrant that the Service or any other related software will always be available, accessible, uninterrupted,-->
          <!--          timely, secure, accurate, complete and error-free, or will operate without packet loss. Subject to section-->
          <!--          Cryptoenter OÜ shall not assume liability of any type for any losses or damages arising out of or in connection-->
          <!--          with the use of the Service, even if the Service was advised and/or aware of the possibility of such losses or-->
          <!--          damages. Your damages are limited to no more than the value of the supported digital currency at issue in the-->
          <!--          transaction. In particular the Service shall not be liable for: Loss of any actual or anticipated profits,-->
          <!--          income, or any indirect, special, incidental or consequential damages and loss of opportunities; Any indirect,-->
          <!--          special, incidental or consequential damages; Any damage to or corruption of data (whether direct or indirect);-->
          <!--          Loss of goodwill or reputation (whether direct or indirect); We shall not be liable for delays, failure in-->
          <!--          performance or interruption of service which result directly or indirectly from any cause or condition beyond-->
          <!--          our reasonable control, including but not limited to, any delay or failure due to any act of God, act of civil-->
          <!--          or military authorities, act of terrorists, civil disturbance, war, strike or other labor dispute, fire,-->
          <!--          interruption in telecommunications or Internet services or network provider services, failure of equipment-->
          <!--          and/or software, other catastrophe or any other occurrence which is beyond our reasonable control and shall not-->
          <!--          affect the validity and enforceability of any remaining provisions. Notwithstanding any other provision of this-->
          <!--          Agreement, any translation of this Agreement is provided for your convenience. The meanings of terms, conditions-->
          <!--          and representations herein are subject to definitions and interpretations in the English language. Any-->
          <!--          translation provided may not accurately represent the information in the original English. We shall not be-->
          <!--          liable for the circumstances that effect the transaction after the altcoins have been sent to the address-->
          <!--          stipulated. If there is a confirmation on blockchain that the altcoins have been sent, our obligations shall be-->
          <!--          treated as fully performed. You shall be responsible for the correctness of the wallet address you specify in-->
          <!--          case of loss of the altcoins because the address proves to be wrong, invalid or unsuitable for the particular-->
          <!--          type of cryptocurrency. Limits First time buyers have to provide their email & mobile phone number, which-->
          <!--          Cryptoenter OÜ will send a test message or call to verify the identity and upload photo/video of the customer-->
          <!--          showing his or her passport or driver’s license, as well as the front of his or her bankcard so that the first-->
          <!--          and last name can be read easily. The first 12 digits of the card number should be covered for security reasons.-->
          <!--          The limit for the first purchase is set to EUR 500, next transaction could be made in 2 business days, EUR 3,000-->
          <!--          limit wil be applied. If the amount of funds deposited in/withdrawn from an account every month exceeds EUR-->
          <!--          10,000, Cryptoenter OÜ may request the account user to provide some additional information, including answers to-->
          <!--          the questions about how he or she developed an interest in bitcoins, the purpose of his or her transactions, his-->
          <!--          or her trading strategy, the user’s biography, and the origins of the funds operated on Cryptoenter OÜ,-->
          <!--          including documentary evidence (e.g., a bank statement showing the receipt of a salary, a contract for the sale-->
          <!--          of an interest in a business, etc.) Privacy policy Protection of Users’ personal data is one of Cryptoenter OÜ-->
          <!--          major priorities. To maintain high level of your privacy protection our company strictly follows the norms of-->
          <!--          EU’s data protection acts, in particular, the General Data Protection Regulation (GDPR) coming into force on-->
          <!--          25th May of 2018. Our privacy policy is based on 6 main principles, ensuring your personal data protection:-->
          <!--          Lawfulness, Fairness and Transparency: data processing is to be performed fairly, in accordance with applicable-->
          <!--          laws and in a transparent manner; Purpose Limitation: data processing is limited to a purpose of the Services-->
          <!--          provision; Data Minimisation: personal data collection takes place in a volume necessary for processing-->
          <!--          purposes; Accuracy: we take all reasonable steps to keep your personal data accurate and up to date, otherwise-->
          <!--          we erase it; Storage Limitation: we store your personal data in a form, which permits to identify you as a data-->
          <!--          subject as long as it is necessary for processing purposes; Integrity and Confidentiality: while processing your-->
          <!--          personal data, we ensure protection against unauthorised or unlawful processing, accidental loss, destruction or-->
          <!--          damage. The Cryptoenter OÜ company collects and processes personal data with your consent, for compliance with a-->
          <!--          legal obligation which Cryptoenter OÜ is subject to or when we have assessed it is necessary for the purposes of-->
          <!--          the legitimate interests pursued by Cryptoenter OÜ or a third party to whom it may be necessary to disclose-->
          <!--          information. We collect and process personal data when it is needed for the performance of our agreements with-->
          <!--          clients in order to provide full-function service and appropriate facilities. Data collection is required in-->
          <!--          order to maintain contact with our clients as well as to provide stable operation of the service and optimize-->
          <!--          it. Personal information helps us to provide security of our clients, Cryptoenter OÜ and third parties. We may-->
          <!--          also use your personal information to send important notices, such as communications about purchases and changes-->
          <!--          to our terms, conditions, and policies because this information is important to your interaction with-->
          <!--          Cryptoenter OÜ. Before using our Services you shall provide express consent to process your personal data-->
          <!--          including, but not limited to your name, address, phone, email, Skype ID as well as any other information-->
          <!--          reported to the Service voluntary for the purposes of the Services provision. You are entitled to withdraw your-->
          <!--          consent at any time. When you use Cryptoenter OÜ Services, we may collect information about your computer,-->
          <!--          mobile device, or other access device for fraud prevention purposes, to monitor for possible breach of your-->
          <!--          Cryptoenter OÜ Account. You may choose to provide us with access to certain personal information stored by third-->
          <!--          parties such as social media sites (such as Facebook and Twitter).To keep your personal data accurate and up- -->
          <!--          to-date we recommend to update it from time to time. We store collected personal date during the period required-->
          <!--          to achieve the goals or during the period required by law. Cryptoenter OÜ does not take any decisions involving-->
          <!--          the use of algorithms or profiling that significantly affects you. We undertake an obligation to process, to-->
          <!--          record, to store, to use and to transfer the personal data provided by maintaining physical, electronic, and-->
          <!--          procedural safeguards in compliance with applicable law. We use computer safeguards such as firewalls and data-->
          <!--          encryption, we enforce physical access controls to our buildings and files, and we authorize access to personal-->
          <!--          information only for the employees/service providers, who are acknowledged to our corporate personal data-->
          <!--          protection policy. Except to the extent required by any applicable law or governmental or judicial body, we will-->
          <!--          not disclose your personal information to any third party without your prior written consent. In case a personal-->
          <!--          data breach occurs we will inform you as well as responsible supervisory authorities about it within 72 hours-->
          <!--          since the moment we’re aware of this fact. In case you decide to share your personal data collected with any-->
          <!--          other companies, we’d like to provide an electronic copy of your personal data on a gratuitous basis. Inter alia-->
          <!--          we respect your “right to be forgotten”: we delete your personal data from our database at your request, except-->
          <!--          in the following cases: the personal data is needed to exercise the right of freedom of expression; there is a-->
          <!--          legal obligation to keep your data; your personal data shall be kept for reasons of public interest (for example-->
          <!--          public health, scientific, statistical or historical research purposes). You have the right to obtain without-->
          <!--          undue delay the rectification of inaccurate personal data concerning him or her. You have the right to obtain-->
          <!--          the erasure of personal data concerning you and restriction of processing without undue delay where one of the-->
          <!--          grounds described in the Regulation applies. We will provide you with access (including a copy) for any purpose-->
          <!--          including to request that we correct the data if it is inaccurate or delete the data if Cryptoenter OÜ is not-->
          <!--          required to retain it by law or for legitimate business purposes. We may decline to process requests that are-->
          <!--          frivolous/vexatious, jeopardize the privacy of others, are extremely impractical, or for which access is not-->
          <!--          otherwise required by local law. In order to change or delete your personal data you can turn for help to the-->
          <!--          agents of our customer support. Moreover in your account you can choose Settings and then press Delete account,-->
          <!--          your request will be sent to our specialist. Your request will be considered within the reasonable time without-->
          <!--          undue delay and you will receive a reasonable response. You can also manage the personal data we collect in the-->
          <!--          Settings in your App. Cookie policy When you access our website or our application or other Cryptoenter OÜ-->
          <!--          Services, we or our counterparts may place small data files called cookies on your computer or other device.-->
          <!--          Cookies were designed to help a website operator determine that a particular user had visited the site-->
          <!--          previously and thus save and remember any preferences that may have been set. Cookies are necessary for the-->
          <!--          website to function and cannot be switched off in our systems. They are usually only set in response to actions-->
          <!--          made by you which amount to a request for services, such as setting your privacy preferences, logging in or-->
          <!--          filling in forms. Cookies allow us to count visits and traffic sources, so we can measure and improve the-->
          <!--          performance of our site. They help us know which pages are the most and least popular and see how visitors move-->
          <!--          around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not-->
          <!--          allow these cookies, we will not know when you have visited our site. We encode our cookies so that only we can-->
          <!--          interpret the information stored in them. You are free to decline our cookies, if your browser or browser add-on-->
          <!--          permits, but doing so may interfere with your use of Cryptoenter OÜ Services. The help section of most browsers-->
          <!--          or browser add-ons provides instructions on blocking, deleting, or disabling cookies.-->
          <template v-if="$i18n.locale === 'ru'">
            1. Общие положения
            1.1. ООО "КРИПТОЭНТЕР", юридическое лицо, зарегистрированное в соответствии с законодательством Российской
            Федерации, ОГРН 1237700055713, адрес места нахождения: 123112, Г.Москва, вн.тер.г. Муниципальный Округ
            Пресненский, наб Пресненская, д. 8, стр. 1, Помещ. 1П, Ком. 41, осуществляет деятельность оператора
            информационной системы (далее – «Система»), в которой осуществляются гражданско- правовые Сделки и/или
            Операций, влекущие за собой переход цифровой валюты от одного обладателя к другому (пунк 3 статьи 14 Закона
            о
            ЦФА) но не ограничиваясь ими, в том числе деятельность по внесению, обработке, хранению информации,
            содержащейся в базах данных Системы.
            1.2. Система представляет собой многокомпонентную автоматизированную систему и использует Блокчейн в части
            отражения записей по Сделкам и/или Операциям.
            1.3. Настоящие Правила устанавливают порядок использования Системы, порядок совершения Сделок, (далее –
            «Правила») и обязательны для ознакомления Пользователями.
            1.4. Настоящие Правила размещены на официальном сайте Оператора в информационно- телекоммуникационной сети
            «Интернет» https://cryptoenter.ru (далее – «Сайт») и вступают в силу с момента их размещения на Сайте.
            Оператор вправе вносить изменения в настоящие Правила и размещать редакции Правил с изменениями и
            дополнениями
            (далее – «Измененные редакции») на Сайте. Измененные редакции вступают в силу для Пользователей по истечении
            15 (пятнадцати) дней с момента их размещения на Сайте, если иное не предусмотрено Правилами.
            1.5. Правила представляют собой оферту Оператора на присоединение Пользователя к настоящим Правилам в
            соответствии со статьей 428 Гражданского кодекса РФ.
            1.6. Пользователь осуществляет акцепт оферты Оператора, указанной в пункте 1.4 настоящих Правил, путем
            совершения конклюдентных действий, предусмотренных пунктом 6.1 настоящих Правил (пункт 3 статьи 438 ГК РФ).
            1.7. Пользователь считается в полном объеме и безоговорочно присоединившимся к настоящим Правилам (пункт 1
            статьи 428 ГК РФ) в момент, когда Регистрация считается завершенной в соответствии с пунктом 6.1.7.
            настоящих
            Правил. С момента полного и безоговорочного присоединения Пользователя к настоящим Правилам в соответствии с
            настоящим пунктом Правила приобретают силу договора в связи с использованием Системы, заключенного между
            Пользователем и Оператором. Если впоследствии Пользователь перестанет быть согласен с каким-либо положением
            Правил, то Пользователь вправе прекратить использование Системы в порядке, предусмотренном разделом Х
            настоящих Правил. При этом настоящие Правила продолжают быть обязательными для Пользователя в части,
            применимой к нему, вплоть до исключения Пользователя из Реестра.
            1.8. Присоединяясь к настоящим Правилам и начиная использовать Систему, Пользователь подтверждает, что:
            - Правила были им полностью прочитаны и их содержание ему полностью понятно;
            - Правила не лишают Пользователя прав, обычно предоставляемых по договорам такого вида, и не содержат явно
            обременительные для Пользователя условия, которые бы Пользователь, исходя из своих разумно понимаемых
            интересов, не принял бы при наличии у него возможности участвовать в определении содержания Правил.

            1.9. Оператор настоящим информирует Пользователей, что цифровые валюты (далее - ЦВ) являются
            высокорискованными активами, их приобретение может привести к потере внесенных денежных средств в полном
            объеме. До совершения Сделок с ЦВ следует ознакомиться с рисками, с которыми связано их приобретение:
            - Деятельность и операции с цифровыми валютами связана с высоким уровнем риска полной потери денежных
            средств
            и иных объектов гражданских прав, переданных в обмен на цифровые валюты (в том числе в результате
            волатильности стоимости, технических сбоев и/или ошибок, совершения противоправных действий, включая
            хищение)
            - Цифровые валюты не являются средством платежа и не обеспечиваются государством.
            - Любые сделки и операции с цифровыми валютами осуществляются лицами исходя из собственной оценки рисков и
            носят необратимый характер.
            - Правовое регулирование сделок с цифровыми валютами не имеет единообразного подхода и последствия
            совершения
            таких сделок могут иметь разную правовую оценку в различных государствах.
            - Используемые технологии (в том числе технология распределенного реестра (blockchain), иной распределенной
            информационной системы и аналогичные ей технологии) не исключают рисков технических сбоев и ошибок в работе.
            1.10. Регистрация Пользователя в Системе, совершение Сделок и/или иных действий с ЦВ в Системе
            осуществляется
            круглосуточно в автоматическом режиме. Процедура Идентификации Пользователя, Пополнения баланса Пользователя
            денежными средствами осуществляется в рабочие дни с 10:00 до 18:30 по местному поясному времени Оператора
            (MSK, UTC +3)
            1.11. Термины, использованные в настоящих Правилах с заглавной буквы, имеют значения, указанные в разделе 2
            настоящих Правил, если в настоящих Правилах не определено иное.
            1.12. Настоящие Правила изложены на русском языке.
            1.13. Любое различие между вариантом на русском языке и переводом на иные иностранные языки трактуется в
            пользу варианта изложенного на русском языке.
            2. Термины и определения
            Адрес - уникальный идентификатор, с которым связана информация о ЦВ в Блокчейн; Алгоритм - программный код,
            разработчиком которого является ООО "КРИПТОЭНТЕР", автоматизирующий логическую цепочку последовательности
            шагов какого-либо процесса; Аутентификационные данные - совокупность логина, пароля и/ приватного ключа
            Пользователя, необходимая для доступа в Систему CRYPTOENTER; Аутентификационные данные Личного кабинета -
            логина Пользователя, пароля и приватного ключа необходимая для доступа в Личный кабинет;
            Аутентификация - удостоверение правомочности обращения Пользователя или уполномоченного представителя
            Пользователя к Системе с использованием Аутентификационных данных и/или Личного кабинета;
            Баланс - информация обо всех ЦВ, Адресах и денежных средствах принадлежащих Пользователю;
            Блокчейн - распределенный реестр, то есть совокупность баз данных, тождественность содержащейся информации в
            которых обеспечивается на основе установленных Алгоритмов (Алгоритма);

            Веб-портал - веб-сайт https://cryptoenter.ru, предоставляющий доступ Пользователям к Системе;
            Владелец - обладатель ЦВ, то есть лицо, включенное в Реестр, имеющее доступ к Системе посредством обладания
            Аутентификационными данными Личного кабинета, которые позволяют ему получать информацию о ЦВ, которыми он
            обладает, а также распоряжаться этими ЦВ посредством использования Системы;
            ГК РФ - Гражданский кодекс Российской Федерации;
            Государственные органы - любой федеральный, региональный или муниципальный орган государственной власти
            (включая орган законодательной, представительной, исполнительной и судебной власти, орган местного
            самоуправления), лица, замещающие должности, устанавливаемые Законодательством, для непосредственного
            исполнения полномочий государственных органов, Банк России, а также любая саморегулируемая и иная
            организация,
            осуществляющая регулирующие, надзорные, лицензионные, регистрирующие или другие государственные или
            квази-государственные функции в соответствии с Законодательством, а также суд;
            Закон о персональных данных - Федеральный закон от 27 июля 2006 года No 152- ФЗ «О персональных данных»;
            Закон о ПОД / ФТ - Федеральный закон от 7 августа 2001 года No 115-ФЗ «О противодействии легализации
            (отмыванию) доходов, полученных преступным путем, и финансированию терроризма»;
            Закон о ЦФА - Федеральный закон от 31 июля 2020 года No 259-ФЗ «О цифровых финансовых активах, цифровой
            валюте
            и о внесении изменений в отдельные законодательные акты Российской Федерации»;
            Законодательство - действующее законодательство Российской Федерации;
            Заявитель - лицо, желающее стать Пользователем;
            Заявка и/или Ордер - заявка или предложение, введенные Пользователем в Систему, которые выражают решительную
            готовность Пользователя совершить Сделку с принадлежащей ему ЦВ и/или денежными средствами;
            Измененные редакции - имеет значение, указанное в пункте 1.4 настоящих Правил; Индивидуальный
            предприниматель
            - физическое лицо, зарегистрированное в установленном Законодательством порядке в качестве индивидуального
            предпринимателя; Инцидент - событие или комбинация событий, указывающие на свершившуюся, предпринимаемую или
            вероятную реализацию угрозы информационной безопасности;
            ИС или Информационная Система - совокупность содержащейся в базах данных информации и обеспечивающих ее
            обработку информационных технологий и технических средств, в которой осуществляется Сделки и/или операции с
            ЦВ;
            ID - уникальный идентификатор, позволяющий однозначно сопоставить его с ЦВ в ИС; Ключи Ноды - набор
            технологических ключей, используемых для подписания блоков, результатов валидации транзакции в Блокчейне;
            Конфиденциальная информация или Конфиденциальные данные - имеет значение, указанное в пункте 12.8 настоящих
            Правил;
            Коин и/или монета - единица учета ЦВ Пользователя в Системе, содержащая информацию о виде ЦВ;
            Поручение на покупку - понимается предложение Пользователя на покупку определенного количества ЦВ по
            установленной Пользователем цене.;

            Поручение на продажу - понимается предложение Пользователя на продажу определенного количества ЦВ по цене,
            установленной Пользователем;
            Личный кабинет - закрытый раздел Системы, использование которого требует прохождения Регистрации и в
            дальнейшем – Аутентификации, предназначенный для (1)Совершения Сделок и отражения информации о денежных
            средствах, переведенных Пользователем для исполнения Сделок; (2) Отражения информации о ЦВ, которыми владеет
            Пользователь; (3) Информационного обмена посредством Системы, в том числе электронными документами между
            Оператором и Пользователями и между Пользователями, в соответствии с настоящими Правилами в пределах,
            соответствующих функциональным возможностям Системы;
            Нода -программно-аппаратный комплекс исполняющий функций Узла blockchain сети, обеспечивающий
            тождественность
            информации, содержащейся в Системе, с использованием процедур подтверждения действительности, вносимых в нее
            (изменяемых в ней) записей;
            НЭП - неквалифицированная квалифицированная электронная подпись, применяется в соответствии с Федеральным
            законом от 06 апреля 2011 года No 63-ФЗ «Об электронной подписи»;
            Оператор - имеет значение, указанное в пункте 1.1 настоящих Правил;
            Оператор сделки - организатор торговли, иное юридическое лицо, иное физическое лицо, личным законом которого
            является российское право, обеспечивающее заключение Сделок с ЦВ и (или) Сделок, связанных с ЦВ, путем сбора
            и
            сопоставления разнонаправленных заявок на совершение Сделок либо путем участия за свой счет в Сделках в
            качестве стороны такой Сделки в интересах третьих лиц, включенное в Реестр Оператора;
            Персональные данные - любая информация, относящаяся к прямо или косвенно определенному или определяемому
            физическому лицу;
            ПО - программное обеспечение, на основании которого осуществляется функционирование Системы;
            ПОД/ФТ - противодействие легализации (отмыванию) доходов, полученных преступным путем, финансированию
            терроризма и финансированию распространения оружия массового уничтожения;
            Пользователь - лицо, соответствующие установленным настоящими Правилами требованиям и прошедшее Регистрацию
            в
            Системе в соответствии с настоящими Правилами;
            Правила - имеет значение, указанное в пункте 1.3 настоящих Правил;
            Регистрация - совокупность действий в Системе, предусмотренная пунктом 6.1 настоящих Правил, результатом
            которых является предоставление Заявителю доступа к Системе и присвоение ему статуса Пользователя;
            Реестр - реестр Пользователей;
            Реестр сделок - реестр сделок, который ведет Оператор;
            Сайт - имеет значение, указанное в пункте 1.4 настоящих Правил;
            Сделка - действия Пользователей, направленные на установление, изменение или прекращение их гражданских прав
            и
            обязанностей, совершаемые с использованием информационных технологий и технических средств Системы;
            Сертификат электронной подписи Оператора - электронный документ, содержащий информацию о владельце ключа,
            области применения ключа и подтверждающий

            принадлежность владельцу открытого ключа. Хранится на ключевом носителе Оператора и используется для
            подтверждения целостности Системы;
            Система - имеет значение, указанное в пункте 1.1 настоящих Правил;
            Сообщения - имеет значение, указанное в пункте 16.1 настоящих Правил;
            Токен - единица учета ЦВ Пользователя в Системе, содержащая информацию о виде ЦВ; ЦВ - совокупность
            электронных данных (цифрового кода или обозначения), содержащихся в информационной системе, которые
            предлагаются и (или) могут быть приняты в качестве средства платежа, не являющегося денежной единицей
            Российской Федерации, денежной единицей иностранного государства и (или) международной денежной или
            расчетной
            единицей, и (или) в качестве инвестиций и в отношении которых отсутствует лицо, обязанное перед каждым
            обладателем таких электронных данных, за исключением оператора и (или) узлов информационной системы,
            обязанных
            только обеспечивать соответствие порядка выпуска этих электронных данных и осуществления в их отношении
            действий по внесению (изменению) записей в такую информационную систему ее правилам;
            2.1. В настоящих Правилах, если из контекста прямо не следует иное:
            - ссылки на разделы и пункты являются ссылками на разделы и пункты настоящих Правил, соответственно;
            - оглавление и заголовки приведены исключительно для удобства ссылки и не влияют на толкование или
            интерпретацию настоящих Правил;
            - слова в единственном числе включают в себя множественное число и наоборот;
            - слова одного рода включают в себя все рода;
            - сроки, указанные в днях, исчисляются в календарных днях;
            - рабочий день означает любой день недели, за исключением (1) выходных дней; (2) нерабочих праздничных дней
            в
            соответствии с Законодательством; (3) дней, объявленных компетентными государственными органами нерабочими;
            - таким словам и выражениям, как «включая» и «в том числе», и другим аналогичным словам, и выражениям, не
            должно придаваться ограничительное значение. Фразы, оканчивающиеся словами «и другие» или «иным образом»,
            или
            другими аналогичными словами и выражениями, не подлежат ограничительному толкованию по причине отношения
            предшествующих слов и выражений к какому- либо конкретному виду или классу, если возможно более широкое
            толкование;
            - в тех случаях, когда в настоящих Правилах даются ссылки на Законодательство, такие
            ссылки должны пониматься как ссылки на Законодательство, действующее на дату, в которую возникает вопрос о
            применении Законодательства в течение срока действия настоящих Правил, включая все изменения в
            Законодательстве после даты вступления настоящих Правил в силу.
            3. Условия использования системы
            3.1. Оператор предоставляет Систему Пользователям для совершения следующих действий:
            - совершение гражданско-правовых операций и/или сделок
            3.2. Оператор не является стороной Сделок, совершаемых в Системе, и не принимает на себя права и обязанности
            сторон по Сделкам, кроме случаев, когда является Агентом Пользователя.

            3.3. Выполняя функции Оператора, Оператор обеспечивает возможность совершения
            Сделок в соответствии с пунктом 6.1. настоящих Правил путем сбора и сопоставления
            заявок и/или Ордеров.
            3.4. Оператор предоставляет Систему в использование на территории Российской Федерации.
            4. Пользователи
            4.1. Пользователями могут быть:
            - физические лица, граждане РФ;
            - индивидуальные предприниматели;
            - юридические лица (за исключением некоммерческих организаций), надлежащим образом
            учрежденные и зарегистрированные в соответствии с Законодательством или применимым
            правом иностранного государства, и не находящиеся в процессе ликвидации на момент
            Регистрации.
            4.2. Лицо становится Пользователем после Регистрации в соответствии с разделом 6 настоящих Правил.
            5. Права и обязанности
            5.1. Права и обязанности Оператора
            5.1.1. Предоставляя Систему в использование в соответствии с разделом 3 настоящих Правил, и исполняя
            возложенные на него Законодательством обязанности, Оператор вправе:
            - изменять функционал Системы в зависимости от наличия технической возможности его предоставления;
            - запрашивать информацию о Пользователях и документы, имеющие отношение к Пользователям, необходимые для
            использования Пользователями Системы, и для осуществления прав и обязанностей Оператора, предоставленных и
            возложенных на него в соответствии с настоящими Правилами и Законодательством;
            - отказывать Пользователям в использовании Системы в соответствии с разделом 3 настоящих Правил, в случае
            несоответствия Пользователей или запрашиваемых ими действий или Сделок настоящим Правилам или
            Законодательству;
            - ограничивать и приостанавливать доступ Пользователей в Систему в случаях, предусмотренных настоящими
            Правилами и Законодательством, в том числе при выявлении фактов или признаков нарушения безопасности
            Системы;
            - устанавливать требования к программно-техническим средствам, необходимым для доступа Пользователя к
            Системе
            и ее использования;
            - передавать информацию о Пользователях и Сделках, а также иных действиях, совершенных с использованием
            Системы, государственным органам в случаях, предусмотренных настоящими Правилами и/ или Законодательством;
            - отклонять заявления Пользователей о совершении Сделок и иных действий с использованием Системы в случаях,
            предусмотренных настоящими Правилами и/ или Законодательством;
            - в одностороннем порядке приостанавливать и отклонять совершение Пользователем Сделок и иных операций с ЦВ
            при выявлении признаков или подозрении на

            несанкционированные действия, неправомерное использование Системы или осуществление иных видов мошенничества
            в
            области информационных технологий;
            - отказывать Пользователю в регистрации в Системе;
            - отказать Пользователям в совершении действий, указанных в разделе 3, при условии, что в результате
            реализации правил внутреннего контроля у Оператора возникают подозрения, что Сделка совершается в целях
            легализации (отмывания) доходов, полученных преступным путем, финансирования терроризма или распространения
            оружия массового уничтожения;
            - осуществлять иные права, предусмотренные настоящими Правилами и Законодательством.
            5.2. Осуществляя деятельность, указанную в разделе 3 настоящих Правил, Оператор обязан:
            - осуществлять проверку лиц, проходящих Регистрацию, Пользователей, предоставленных ими информации и
            документов на соответствие настоящим Правилам и Законодательству; - обеспечить доступ Владельца к записям
            Системы, имеющим отношение к данному Владельцу (в частности, записям о принадлежащих ему ЦВ) посредством
            Системы, а также возможность возобновления доступа Владельца к записям Системы, имеющим отношение к данному
            Владельцу (в частности, записям о принадлежащих ему ЦВ) не позднее дня следующего за днем восстановления
            доступа к Системе, если такой доступ ранее был им утрачен;
            - обеспечить бесперебойность и непрерывность функционирования Системы, в том числе наличие и надлежащее
            функционирование дублирующих (резервных) технологических и операционных средств, обеспечивающих
            бесперебойное
            и непрерывное функционирование Системы;
            - обеспечить целостность и достоверность информации о ЦВ, содержащейся в записях Системы;
            - обеспечить корректность реализации в Системе установленных Оператором алгоритмов создания, хранения и
            обновления информации, содержащейся в Блокчейне, и алгоритмов, обеспечивающих тождественность указанной
            информации во всех базах данных, составляющих Блокчейн, а также невозможность внесения изменений в
            установленные Оператором алгоритмы иными лицами;
            - обеспечить хранение информации о Сделках и об участниках таких Сделок не менее 5 (пяти) лет с даты
            совершения соответствующих Сделок;
            - осуществлять ведение Реестра и вносить в него изменения;
            - предоставлять Пользователю информацию, в том числе информацию о ЦВ, Владельцем которых является
            Пользователь, о Сделках, совершаемых или совершенных Пользователем в Системе, и иную информацию,
            предусмотренную настоящими Правилами или Законодательством;
            - предоставлять информацию государственным органам и иным лицам в объеме и в случаях, предусмотренных
            Законодательством;
            - выполнять иные обязанности, предусмотренные настоящими Правилами и Законодательством.
            5.3. Права и обязанности Пользователя
            5.3.1. Пользователь имеет право:
            - на доступ к Системе при соответствии Пользователя требованиям настоящих Правил, внесении Пользователя в
            Реестр и соблюдении Пользователем настоящих Правил

            - использовать Систему для совершения Сделок, включая приобретение ЦВ, продажу ЦВ, отчуждение ЦВ, обмен ЦВ,
            иные Сделки связанные с ЦВ;
            - запрашивать и получать информацию о ЦВ, Владельцем которых является Пользователь, Сделках, совершаемых или
            совершенных Пользователем в Системе и иную информацию, предусмотренную настоящими Правилами или
            Законодательством;
            - обращаться к Оператору с запросами, связанными с функционированием Системы и технической поддержкой
            Пользователя;
            - на защиту Оператором информации, предоставленной Пользователем Оператору;
            - осуществлять иные права, предусмотренные настоящими Правилами и Законодательством.
            5.4. При использовании Системы Пользователь обязан:
            - соблюдать настоящие Правила;
            - использовать функционал Системы исключительно в соответствии с его целевым назначением;
            - не предпринимать попытки обойти технические ограничения, установленные Системой; - не нарушать работу
            Системы;
            - не содействовать каким-либо действиям, направленным на нарушение ограничений и запретов, налагаемых
            настоящими Правилами или Законодательством;
            - не копировать и не изменять исходный код страниц сайта, на которых отображаются элементы Системы;
            - не осуществлять несанкционированный доступ в Личные кабинеты иных Пользователей каким-либо образом, в том
            числе путем подборки или введения пароля, а также не предпринимать попытки такого доступа;
            - предоставлять Оператору полную и достоверную информацию, необходимую для предоставления Пользователю
            доступа
            к Системе и использования Пользователем Системы, включения Пользователя в Реестр, а также для проверки
            соответствия осуществляемых Пользователем Сделок и действий в Системе требованиям Законодательства и для
            обеспечения соблюдения требований Законодательства Оператором;
            - уведомлять Оператора об изменениях в данных Пользователя, иной информации, необходимой для предоставления
            Пользователю доступа к Системе и использования Пользователем Системы, а также информации, включенной в
            Реестр;
            - отвечать на запросы Оператора и предоставлять ему информацию, указанную в запросе, в случаях,
            предусмотренных настоящими Правилами и Законодательством;
            - установить программно-технические средства, необходимые для использования Системы и защиты информации, и
            поддерживать их работоспособность;
            - незамедлительно информировать Оператора о сбоях в работе Системы и иных Инцидентах, о которых Пользователю
            стало известно, и в случае этого незамедлительно приостановить использование Системы;
            - не передавать свои Аутентификационные данные Личного кабинета третьим лицам;
            - не использовать Систему для совершения сделок, направленных на легализацию (отмывание) доходов, полученных
            преступным путем, финансирование терроризма, финансирование распространения оружия массового уничтожения,
            осуществление коррупционной деятельности, иной запрещенной Законодательством деятельности, а также для
            совершения иных запрещенных Законодательством сделок и действий;

            - выполнять иные обязанности, предусмотренные настоящими Правилами и Законодательством.
            5.5. Пользователь Обязан не размещать и не распространять в ИС:
            - информацию, распространение которой в Российской Федерации запрещено; - информацию, распространяемую с
            нарушением Законодательства;
            - оскорблять и угрожать другим Пользователям и/или сотрудникам Системы;
            - использовать ненормативную лексику в любой форме;
            - высказываться с целью разжигания межнациональной розни, пропагандировать насилие в любой форме, оскорблять
            религиозные чувства других Пользователей;
            - распространять ссылки на внешние ресурсы в любой форме;
            - постоянно размещать одну и туже информацию или повторять в тексте одну и туже фразу;
            - информацию, распространяемую с нарушением прав и законных интересов третьих лиц, в том числе авторских,
            смежных прав и иных прав на результаты интеллектуальной деятельности и средства индивидуализации;
            - ложную, вводящую в заблуждение, уничижительную или оскорбительную информацию; - недобросовестную и
            недостоверную рекламу, а также рекламу, распространяемую с нарушением Законодательства;
            - иную информацию, распространение которой в Российской Федерации ограничено Законодательством.
            6. Регистрация и доступ
            6.1. Процедура Регистрации включает следующую последовательность действий:
            6.1.1. ознакомление Заявителя с настоящими Правилами, иными обязательными политиками Оператора, ссылка на
            которые размещается на странице входа в Систему; 6.1.2. подтверждение Заявителем, что он ознакомился с
            настоящими Правилами, в том числе порядком обработки Персональных данных, приведенным в разделе 12 настоящих
            Правил, иными обязательными политиками Оператора, согласен с настоящими Правилами, в том числе порядком
            обработки Персональных данных, приведенным в разделе 12 настоящих Правил, иными обязательными политиками
            Оператора, и понимает, что с момента, предусмотренного пунктом 6.1.8 настоящих Правил, он в соответствии с
            пунктом 1.7 настоящих Правил будет считаться в полном объеме и безоговорочно присоединившимся к настоящим
            Правилам, одновременно путем проставления знака согласия в соответствующих полях выводимой экранной формы
            и/или путем проставления Электронной подписи Заявителя под электронным документом, который будет выведен на
            странице входа в Систему или на который на странице входа в Систему будет размещена ссылка;
            Если Заявитель не может или не желает подтвердить какое-либо из обстоятельств, указанных в настоящем пункте
            6.1.2 настоящих Правил, Заявитель должен немедленно покинуть страницу входа в Систему;
            6.1.3. Регистрация пользователя в Информационной системе Оператора осуществляется через регистрационную
            экранную форму расположенную на Веб Портале Оператора в сети Интернет по постоянному адресу
            https://cryptoenter.ru/registration введением адреса электронной почты пользователя.

            6.1.4. Подтверждение действительности и принадлежности адреса электронной почты Пользователя осуществляется
            отправкой на указанный адрес электронной почты сообщения содержащего ссылку на экранную форму завершения
            регистрации.
            6.1.5. Процедура регистрации осуществляется через экранную форму для введения пароля и нажатия кнопки
            «Подтвердить регистрацию».
            6.1.6. В завершение регистрации Пользователю системы Оператора создаётся приватный ключ для доступа и
            совершения всех операций в Системе, хранящийся и пользователя. 6.1.7. Регистрация Пользователя считается
            завершенной после нажатия кнопки «Сохранить и войти»
            6.1.8. В дальнейшем Аутентификация Пользователя в Системе и подключение Пользователя к Системе
            осуществляется
            с помощью Аутентификационных данных, сочетание логина, пароля и приватного ключа доступа в Систему через
            экранную форму расположенную по постоянному адресу https://cryptoenter.ru/login.
            7. Реестр
            7.1. Оператор ведет Реестр. В Реестр в отношении каждого Пользователя включается следующая информация:
            - сведения о Пользователе;
            - сведения, необходимые для Аутентификации;
            - сведения, необходимые для Идентификации;
            7.2. Документы, подтверждающие сведения, подлежащие включению в Реестр и указанные в пункте 7.1 настоящих
            Правил, хранятся в течение всего срока нахождения Пользователя в Реестре, а также в течение 5 (пяти) лет
            после
            его исключения из Реестра, если более длительный срок хранения не установлен Законодательством.
            7.3. Документы, подтверждающие сведения, подлежащие включению в Реестр и указанные в пункте 7.1 настоящих
            Правил, в зависимости от типа сведений, которые они подтверждают, хранятся Оператором в электронной форме (в
            том числе в виде электронных копий документов, представленных на бумажных носителях) в подсистемах Системы,
            а
            также в иных информационных системах, являющихся частью Системы, в частности, обеспечивающих
            функционирование
            Системы. Оператор обеспечивает целостность и неизменность данных документов, возможность поиска и выгрузки
            на
            внешние носители информации из таких документов на конкретную дату.
            8. Порядок совершения операций и учет в системе
            8.1. Идентифицированным Пользователям Системы в Личном кабинете доступны следующие виды операции:
            - покупка цифровой валюты
            - продажа цифровой валюты
            - перевод цифровой между пользователями
            - обмен цифровых валют между пользователями
            8.2. Проведение операции пользователя осуществляется через Личный кабинет Системы. 8.3. Пользователь
            направления поручения на проведение операции в Систему путем заполнения экранной формы соответствующей виду
            операции разделе Личного кабинета. 8.4. Подтверждение поручения пользователь осуществляет через экранную
            форму
            соответствующую виду операции нажатием соответствующей кнопки, введением пароля и приватного ключа.
            8.5. Поручение пользователя исполняется Системой в автоматическом режиме по алгоритмам системы указанных в
            Разделе 11 настоящего соглашения.

            8.6. Результат обработки и исполнения поручения пользователя отображается в Личном кабинете Пользователя.
            8.7. Отчет об операциях клиента отображается в соответствующем разделе Личного кабинета. Документарное
            подтверждение и отчет об исполненных поручениях и проведенных операциях предоставляется Системой по запросу
            Пользователя путем отправки в электронной форме файла формата PDF на адрес электронной почты указанный при
            регистрации.
            9. Плата за использование системы
            9.1. Пользователи уплачивают Оператору следующие виды вознаграждений:
            - Торговая комиссия - вознаграждение Оператора за совершение Сделки с использованием
            Системы;
            - Комиссия по выводу - вознаграждение Оператора за проведение и/или оформление
            операции вывода ЦВ и/или денежных средств из Системы.
            9.2. Конкретные размер и порядок уплаты вознаграждения Оператора будет
            устанавливаться тарифами, принимаемыми Оператором. Информация о действующих
            тарифах Оператора доступна Пользователям в разделе «Комиссия и Лимиты» на сайте
            Оператора.
            9.3. Вознаграждение Оператора, уплачивается в российских рублях. В элементах
            интерфейса Системы для упрощения восприятия информации и расчетов, Вознаграждения
            Оператора, Комиссии и Лимиты указаны в ЦВ и/или денежных средствах, но производятся
            в рублях.
            9.4. Вознаграждение Оператора уплачивается Пользователем в момент проведения Сделки и/или операции вывода ЦВ
            и/или денежных средств.
            10. Защита информации
            10.1. Оператор в соответствии с нормативными актами и с проведением регулярной оценки обеспечивает защиту
            информации, получаемой, подготавливаемой, обрабатываемой, передаваемой и хранимой в используемых им для
            работы
            Системы автоматизированных системах, в том числе:
            - информации о Пользователях, включая их Персональные данные и/или Персональные данные их представителей;
            - информации, на основании которой осуществляется обращение ЦВ, в том числе информации, содержащейся в
            электронных сообщениях – указаниях о внесении или изменении записи о ЦВ в Систему по указанию Пользователей;
            - информации обо всех совершенных Сделках, участниках таких Сделок и иной Конфиденциальной информации;
            - информацию о Адресах блокчейна, Адресах Узла (Ноды) и сертификата электронной подписи Оператора.
            10.2. Защита информации и обеспечение операционной надежности Системы обеспечиваются Оператором путем
            применения, помимо прочего, следующих мер:
            - использования многофакторной Аутентификации при осуществлении доступа к Системе; - обеспечения
            конфиденциальности информации о Сделках за счет применения Узлами механизма проверки;
            - взаимной аутентификации при обмене сообщениями между любыми компонентами ИС;
            - шифрования канала обмена данными между Нодами, применения протокола установления безопасного соединения
            между Нодами;

            - контроля целостности сообщений, определения Пользователей, совершающих Сделки, их полномочий на совершение
            Сделок;
            - применения организационных и технических мер, обеспечивающих обработку Инцидентов, связанных с
            несанкционированным доступом к криптографическим ключам при их формировании, использовании и хранении, в том
            числе в соответствии с требованиями технической документации на средства криптографической защиты
            информации;
            - реализации системы мониторинга Инцидентов и регламентации мер по реакции на Инциденты;
            - анализа трафика сетевого взаимодействия между Нодами с целью обеспечения непрерывности внесения
            (изменения)
            записей в Блокчейн; обеспечения блокировки потенциально опасных записей в Блокчейне, способных привести к
            изменению последовательности записей в Блокчейне, обеспечения анализа и контроля алгоритма (ов),
            обеспечивающего (их) тождественность информации, содержащейся во всех базах данных, составляющих Блокчейн,
            направленных на обеспечение невозможности реализации компьютерных атак, в том числе со стороны Узлов путем
            управления указанными алгоритмами;
            - применения криптографических алгоритмов с учетом оценки их стойкости к актуальным угрозам безопасности
            информации для предотвращения атак, направленных на криптографические алгоритмы и протоколы;
            - использования Узлами безопасного программного кода и протоколов с учетом актуальных угроз безопасности и
            применяемых информационных технологий, в том числе реализации системы защиты от атак, направленных на отказ
            в
            обслуживании с возможностью фильтрации передаваемых данных, хранения Нодами доверенных сетевых адресов и
            реализации механизма проверки некорректности работы Нод;
            - ограничение прав доступа к данным для сотрудников, администраторов ИС и технологических учетных записей
            ИС,
            в том числе с использованием шифрования на этапах хранения и передачи информации.
            10.3. В отношении используемых Ключей Нод и Сертификата электронной подписи
            Оператора, дополнительно применяется:
            - специализированные программные решения для хранения технологических Ключей Ноды в средах виртуализации;
            - специализированные сертифицированные на территории Российской Федерации для работы со средствами
            криптографической защиты информации программно-аппаратные средства для хранения и использования Сертификата
            электронной подписи Оператора. 10.4. Операционная надежность обеспечивается Оператором в соответствии со
            следующими условиями:
            - поддержание работоспособности Системы, устранение в разумные сроки, но не более чем в течение трех рабочих
            дней, сбоев в работе Системы, возникших по вине Оператора; - осуществление технической поддержки
            Пользователя
            по вопросам работы с Системой. Техническая поддержка осуществляется в рабочие дни с 10:00 до 18:00 по
            московскому поясному времени. Контактная информация по вопросам технической поддержки, размещена на Сайте.
            10.5. Применение Оператором мер по защите информации и обеспечению операционной надежности осуществляется в
            соответствии с Законодательством и внутренними документами Оператора.

            10.6. Оператор применяет следующие меры для обеспечения целостности информации в ИС и достижения высокого
            уровня доступности:
            - дублирование сред исполнения программного кода;
            - использование специализированного протокола для достижения консенсуса между Узлами;
            - хранение и обработка данных о владельцах ЦВ в виде цепочки блоков (Блокчейн) для достижения контроля
            целостности истории операций с использованием специализированной платформы Hyperledger;
            - использование механизмов автоматической проверки Нодами корректности Сделок.
            11. Алгоритмы системы
            11.1. Использование Алгоритмов в Системе
            11.1.1. Тождественность содержащейся в Системе информации обеспечивается на основе установленных Алгоритмов.
            11.1.2. Алгоритмы Системы подлежат размещению на Узле. Тождественность информации на различных Узлах
            распределенной Системы достигается благодаря консенсусу Узлов. 11.1.3 Виды Алгоритмов Системы:
            - Алгоритмы управления ЦВ в Системе;
            - Алгоритмы управления движением ЦВ в Системе;
            - Алгоритм формирование текущей стоимости ЦВ
            - Алгоритмы обмена ЦВ в Системе.
            - Алгоритмы сопоставления заявок Пользователей в Системе.
            - Алгоритмы учета ЦВ Пользователя в Системе.
            - Алгоритмы учета денежных средств Пользователя в Системе.
            11.2. Внесение изменений в программы Алгоритма Системы.
            11.2.1. Оператор вправе в одностороннем порядке вносить изменения в программы Алгоритма Системы.
            11.2.2. Внесение изменений в программы Алгоритма Системы направлено, в том числе, на расширение ее
            функциональных возможностей, поддержание и улучшение ее безопасности, операционной надежности и
            работоспособности. Оператор обеспечивает, чтобы внесение изменений в программу Алгоритма Системы не
            затрагивало права Пользователей, зарегистрированных в Системе до момента внесения соответствующих изменений,
            в
            том числе не изменяло условия их доступа к Системе и право распоряжения ими принадлежащих им ЦВ, не изменяло
            условий Сделок и иных действий с ЦВ, инициированных, совершенных или исполненных до момента внесения
            соответствующих изменений.
            11.2.3. Перед внесением изменений в программу Алгоритма Системы Оператор проверяет и тестирует
            соответствующие
            изменения на устойчивость к угрозам информационной безопасности, операционную надежность, сопоставимость с
            иными элементами Системы, влияние на использование Системы Пользователями.
            11.2.4. Оператор информирует Пользователей о внесении изменений в программу Алгоритма Системы, если эти
            изменения затрагивают использование Системы Пользователями, в порядке, предусмотренном разделом 16 настоящих
            Правил, путем размещения соответствующего объявления на Сайте либо иным способом по выбору Оператора. В иных
            случаях Пользователь не информируется о внесении изменений в программу Алгоритма Системы.

            12. Персональные и Конфиденциальные данные
            12.1. Оператор осуществляет обработку Персональных данных Пользователей, которые необходимы для совершения
            действий в Системе, предусмотренных разделом 3 настоящих Правил, в соответствии с положением настоящих
            Правил
            и требованиями Законодательства, с согласия Пользователя на обработку персональных данных (далее - Согласие
            Пользователя на ОПд) являющегося неотъемлемой часть настоящих правил
            12.2. Оператор осуществляет обработку Персональных данных:
            - Пользователей, являющихся физическими лицами и ИП, с целью предоставления им доступа к Системе для
            совершения Сделок с ЦВ. При этом основанием для обработки Персональных данных указанной категории лиц
            является
            пункт 5 части 1 статьи 6 Закона о персональных данных;
            - уполномоченных представителей Пользователей, являющихся юридическими лицами, с целью предоставления
            Пользователям доступа к Системе для совершения Сделок с ЦВ. При этом Пользователи гарантируют и заверяют,
            что
            обладают законными основаниями, с учетом положений пункта 12.5 настоящих Правил, для передачи Персональных
            данных указанной категории лиц в адрес Оператора и их дальнейшей обработки Оператором в приведённых выше
            целях.
            12.3. Оператор осуществляет обработку Персональных данных в объеме, необходимом для совершений действий в
            Системе, предусмотренных разделом 3 настоящих Правил.
            12.4. В процессе обработки Персональных данных Оператор совершает с ними действия всеми доступными способами
            (в том числе путем автоматизированной и неавтоматизированной обработки), включая: сбор, систематизацию,
            накопление, хранение, уточнение (обновление, изменение), использование, передачу (предоставление, доступ),
            обезличивание, блокирование и уничтожение.
            12.5. Персональные данные уполномоченных представителей Пользователей, являющихся юридическими лицами, либо
            уполномоченных представителей иных Пользователей передаются Оператору соответствующими Пользователями.
            Операторами Персональных данных в отношении Персональных данных таких лиц и ответственными лицами в
            отношении
            обработки Персональных данных таких лиц являются Пользователи, чьими представителями данные лица являются.
            12.6. Оператор и Пользователи, которым в соответствии с настоящими Правилами передаются Персональные данные,
            обязаны обеспечить конфиденциальность обрабатываемых Персональных данных и исполнение иных обязанностей,
            предусмотренных Законом о персональных данных.
            12.7. В части, не урегулированной настоящим разделом Правил, обработка Персональных данных осуществляется в
            соответствии с положениями Закона о персональных данных. 12.8. В рамках совершения действий в Системе,
            предоставляемых Оператором в соответствии с разделом 3 настоящих Правил, «Конфиденциальными данными» и/или
            «Конфиденциальной информацией» является информация:
            - о Сделках, совершенных конкретными Пользователями;
            - о расчетах по данным Сделкам;
            - иная информация, полученная Пользователями в ходе исполнения Сделок;
            - о реквизитах Пользователей;
            - Персональные данные Пользователей, являющихся Индивидуальными предпринимателями;
            - Персональные данные уполномоченных представителей Пользователей.

            12.9. Конфиденциальная информация может быть предоставлена Оператором:
            - конкретному Пользователю по Сделкам, в которых он участвует в качестве стороны или
            Уполномоченного Пользователя, в отношении конкретного Пользователя или
            Пользователей, участвующих в качестве стороны или Уполномоченного Пользователя в
            этих же Сделках;
            - с согласия Пользователей лицам, указанным в таком согласии;
            - Банку для организации денежных расчетов в Системе с использованием денежных
            средств;
            - государственным органам и иным лицам в случаях, предусмотренных
            Законодательством;
            - в иных случаях, связанных с исполнением Оператором своих прав и обязанностей,
            предусмотренных настоящими Правилами и Законодательством.
            12.10. Пользователи обязаны хранить в тайне и не раскрывать третьим лицам
            Аутентификационные данные Личного кабинета. В случае если Аутентификационные
            данные Личного кабинета станут известны третьим лицам, Пользователи обязаны
            немедленно изменить Аутентификационные данные Личного кабинета.
            12.11. Пользователи не вправе без письменного согласия Оператора передавать третьим
            лицам любые сведения, которые стали им известны в связи с использованием Системы и
            исполнением настоящих Правил, кроме случаев, связанных с исполнением возложенных
            на Пользователей Законодательством обязанностей и с защитой интересов Пользователей
            в установленном Законодательством порядке.
            12.12. Обязанности, предусмотренные настоящим разделом, действуют в течение неограниченного периода времени.
            13. Налогообложение
            13.1. Пользователи самостоятельно уплачивают налоги и сборы и выполняют иные
            обязанности, предусмотренные налоговым Законодательством, связанные с совершением
            Сделок и иных действий с использованием Системы.
            13.2. Оператор самостоятельно уплачивает налоги и сборы и выполняет иные обязанности, предусмотренные
            налоговым Законодательством и связанные с деятельностью Оператора.
            14. Интеллектуальная собственность
            14.1. Исключительное право на ПО, включая исходный текст, объектный код, подготовительные материалы,
            полученные в ходе разработки ПО, порождаемые ПО визуальные отображения, иные элементы ПО, право на название
            ПО, право на информацию, содержащуюся в ПО, принадлежат Оператору (Свидетельство о государственной
            регистрации
            программ для ЭВМ No 2023660009).
            14.2. Оператор предоставляет Пользователям простую безвозмездную неисключительную лицензию на использование
            ПО
            на весь срок действия исключительного права на ПО на территории Российской Федерации.
            14.3. Пользователь вправе использовать ПО исключительно для целей использования
            функционала Системы в своей личной или коммерческой деятельности. Пользователь не вправе распространять
            экземпляры ПО каким-либо образом, осуществлять его доведение до всеобщего сведения, осуществлять его
            переработку (модификацию), декомпилирование или модификацию программных компонентов Системы или использовать
            ПО иным

            способом, не связанным с целью использования ПО, предусмотренной настоящим пунктом.
            15. Взаимодействие с государственными органами
            15.1. В рамках взаимодействия Оператора с государственными органами и иными лицами: - не позднее рабочего
            дня,
            следующего за днем получения соответствующего требования, на основании вступившего в законную силу судебного
            акта, исполнительного документа, в том числе постановления судебного пристава – исполнителя, актов других
            органов и должностных лиц при осуществлении ими своих функций, предусмотренных Законодательством, либо
            выданного в порядке, предусмотренном Законодательством, свидетельства о праве на наследство,
            предусматривающего переход ЦВ определенного вида в порядке универсального правопреемства, Оператор
            обеспечивает внесение (изменение) записей о ЦВ;
            15.2. Оператор предоставляет содержащуюся в записях Системы информацию о ЦВ, принадлежащих их Владельцу:
            - по требованию суда;
            - по требованиям федерального органа исполнительной власти, принимающего меры по противодействию легализации
            (отмыванию) доходов, полученных преступным путем, финансированию терроризма и финансированию распространения
            оружия массового уничтожения, органов принудительного исполнения Российской Федерации, налоговых органов,
            других органов и должностных лиц в случаях, предусмотренных законодательными актами об их деятельности, а
            также на основании судебного решения в случаях, если такая информация необходима для осуществления ими своих
            функций, предусмотренных Законодательством;
            - при наличии согласия руководителя следственного органа - по требованию органов предварительного следствия
            по
            делам, находящимся в их производстве;
            - на основании судебного решения - должностным лицам органов, уполномоченных осуществлять
            оперативно-розыскную
            деятельность, при выполнении ими функций по выявлению, предупреждению и пресечению преступлений по их
            запросам, направляемым в суд в порядке, предусмотренном статьей 9 Федерального закона от 12 августа 1995
            года
            No 144-ФЗ «Об оперативно-розыскной деятельности», при наличии сведений о признаках подготавливаемых,
            совершаемых или совершенных преступлений, а также о лицах, их подготавливающих, совершающих или совершивших,
            если нет достаточных данных для решения вопроса о возбуждении уголовного дела;
            - по запросам, направляемым уполномоченными лицами в соответствии с
            законодательством Российской Федерации о противодействии коррупции;
            - по требованию конкурсного управляющего в ходе конкурсного производства в
            отношении Владельца ЦВ;
            15.3. Взаимодействие Оператора с государственными органами и иными лицами в иных случаях регулируется
            Законодательством.
            16. Информационное взаимодействие
            16.1. Все уведомления, извещения, сообщения (далее – «Сообщения»), направляемые Оператором в связи с
            настоящими Правилами и использованием Системы, могут быть направлены Оператором Пользователю одним из
            следующих способов:

            - с использованием функционала Системы путем передачи сообщения через Личный кабинет, с фиксацией в
            Сообщении
            (в том числе с использованием автоматического проставления отметки программными средствами Системы)
            содержания
            такого Сообщения, в том числе в виде электронного документа и (или) скан-копии документа;
            - вручения нарочным, курьерской доставкой либо путем направления заказного письма с уведомлением о вручении
            по
            адресу регистрации Пользователя, являющегося физическим лицом;
            - вручения нарочным, курьерской доставкой либо путем направления заказного письма с уведомлением о вручении
            по
            юридическому адресу Пользователя, являющегося юридическим лицом или Индивидуальным предпринимателем,
            указанному в Едином государственном реестре юридических лиц или в Едином государственном реестре
            индивидуальных предпринимателей, соответственно;
            - путем направления Сообщения (за исключением Сообщения, содержащего информацию, отнесенную к категории
            банковской тайны, коммерческой тайны, и/или содержащего Персональные данные) Пользователю на адрес
            электронной
            почты, указанный в Личном кабинете, с адреса электронной почты Оператора, в том числе в виде электронного
            документа и/или скан-копии документа.
            16.2. Все Сообщения, направляемые Пользователем Оператору в связи с настоящими Правилами и использованием
            Системы, могут быть направлены Пользователем Оператору одним из следующих способов:
            - с использованием Личного кабинета, в том числе в виде электронного документа и/или скан-копии документа;
            - курьерской доставкой либо путем направления заказного письма с уведомлением о вручении по адресу
            Оператора,
            указанному на Сайте;
            - путем направления Сообщения Оператору на адрес электронной почты Оператора, в том числе в виде
            электронного
            документа и/или скан-копии документа.
            16.3. Сообщение считается доставленным (врученным) получателю:
            - в случае направления с использованием Личного кабинета – не позднее следующего рабочего дня после
            отправления отправителем;
            - в случае вручения нарочным – в дату фактического вручения Сообщения получателю, подтвержденного подписью
            получателя или уполномоченного представителя получателя (под расписку), или в дату подтверждения невручения
            сообщения в связи с отсутствием получателя по указанному в Сообщении адресу;
            - в случае курьерской доставки – в дату, указанную в подтверждении о вручении отправления получателю,
            имеющемся у отправителя, или в дату подтверждения невручения Сообщения в связи с отсутствием получателя по
            указанному в Сообщении адресу;
            - в случае отправки заказного письма с уведомлением о вручении – в дату, указанную в уведомлении о вручении,
            либо в дату отказа получателя от получения направленного Сообщения при фиксации данного факта организацией
            почтовой связи, либо в дату, на которую Сообщение не вручено при фиксации данного факта организацией
            почтовой
            связи;
            - в случае направления по электронной почте – в дату получения отчета о доставке, автоматически
            направляемого
            почтовым сервером получателя, или по истечении 24 часов с момента отправки Сообщения, в зависимости от того,
            что наступило ранее.

            16.4. Сообщение считается доставленным и в тех случаях, когда оно поступило соответствующему лицу, но по
            обстоятельствам, зависящим от него, не было ему вручено или лицо не ознакомилось с ним.
            16.5. Оператор не несет ответственности за неполучение или несвоевременное получение Пользователем
            Сообщений,
            направленных Оператором одним или несколькими способами, предусмотренными настоящим разделом 16, по
            причинам,
            зависящим от Пользователя. Примерами причин, зависящих от Пользователя, среди прочего, считаются случаи
            необеспечения или ненадлежащего обеспечения Пользователем возможности получения Сообщений способами,
            предусмотренными настоящим разделом 16, сбоев в работе систем связи, энергоснабжения, программного
            обеспечения, оборудования на стороне Пользователя, несовершения или несвоевременного совершения
            Пользователем
            действий для своевременного и надлежащего получения Пользователем Сообщений, направляемых Оператором.
            16.6. В случае изменения у Пользователя контактных или иных данных Пользователь обязан проинформировать об
            этом Оператора до вступления изменений в силу или в течение 2 (двух) рабочих дней с момента возникновения
            таких изменений. Пользователь, не сообщивший об изменениях, несет риск вызванных этим неблагоприятных
            последствий.
            16.7. Оператор уведомляет Пользователей об изменении его контактных данных, в том числе адреса электронной
            почты, с которого могут направляться Сообщения Пользователям или на который могут направлять Сообщения
            Пользователи, путем размещения обновленной информации на Сайте. Такое размещение информации считается
            надлежащим уведомлением Пользователей о таких изменениях.
            16.8. Пользователь несет риск неблагоприятных последствий ввиду неполучения Сообщений по причине отсутствия
            Пользователя или его уполномоченного представителя по юридическому адресу Пользователя, являющегося
            юридическим лицом или Индивидуальным предпринимателем, указанного в Едином государственном реестре
            юридических
            лиц или в Едином государственном реестре индивидуальных предпринимателей, соответственно.
            17. Порядок разрешения споров
            17.1. В случае возникновения споров и разногласий, возникающих из настоящих Правил или в связи с ними,
            стороны
            обязаны предпринять все меры к их разрешению путем переговоров.
            17.2. Сторона, инициирующая процедуру разрешения споров, обязана направить другой стороне претензию с
            изложением сути возникших разногласий.
            17.3. Сторона, получившая претензию, обязана в течение 30 (тридцати) дней с даты ее получения предоставить
            мотивированный ответ.
            17.4. Если спор не разрешен в порядке, предусмотренном пунктами 17.1 – 17.3 настоящих Правил, либо сторона,
            получившая претензию, не предоставила на нее мотивированный ответ в течение срока, указанного в пункте 17.3
            настоящих Правил, то претензионный порядок считается соблюденным, и сторона вправе обратиться в суд после
            истечения срока, предусмотренного пунктом 17.3 настоящих Правил.
            17.5. Если Законодательством не предусмотрено иное, споры, возникающие из настоящих Правил или в связи с
            ними,
            разрешаются мировым судьей, в районном суде или в арбитражном суде по месту нахождения Оператора.

            18. Раскрытие информации
            18.1. Информация об Операторе, настоящие Правила, тарифы за использование Системы, установленные Оператором,
            и
            иные документы, касающиеся использования Системы Пользователем, раскрыты Оператором на Сайте.
            18.2. Информация и документы, указанные в пункте 18.1 настоящих Правил, обновляются Оператором по мере
            внесения в них изменений. Пользователи обязаны самостоятельно отслеживать изменения, вносимые в данную
            информацию и документы.
            19. Конфликт интересов
            19.1. Оператор принимает установленные его внутренними документами меры, направленные на предотвращение
            конфликта интересов при обеспечении совершения Сделок и иных действий с использованием Системы.
            20. Наследование
            20.1. В случае поступления Оператору документов, подтверждающих смерть Владельца, ЦВ Владельца блокируются
            до
            предъявления документов, подтверждающих переход прав на ЦВ к другому(им) Владельцу(ам).
            20.2. Блокирование ЦВ осуществляется на основании одного из следующих документов: - справки и/или запроса
            нотариуса, исполнителя завещания либо должностного лица, уполномоченного законом на совершение нотариальных
            действий;
            - свидетельства о смерти;
            - вступившего в законную силу решения суда об объявлении физического лица умершим. 20.3. При получении
            запроса
            Оператор предоставляет нотариусу, исполнителю завещания либо должностному лицу, уполномоченному законом на
            совершение нотариальных действий, отчет о совершении операций о блокировании ЦВ Владельца и/или выписку
            Баланса Владельца.
            20.4. Оператор по запросу нотариуса выдает ему выписку Баланса Владельца. При наличии специального указания
            в
            данном запросе данную выписку на руки может получить наследник.
            20.5. В случае если ЦВ завещаны организации или лицу, не являющемуся родственником Владельца, вместо
            свидетельства о смерти может быть предоставлена справка или выписка из актовой книги городского или
            районного
            отдела записи актов гражданского состояния. Данная справка, подтверждающая факт смерти лица, завещавшего ЦВ,
            должна быть подписана уполномоченным лицом и скреплена гербовой печатью соответствующего органа записи актов
            гражданского состояния.
            20.6. К документам, подтверждающим переход прав на ЦВ в связи со смертью Владельца, относятся:
            - свидетельство о праве на наследство, выданное нотариусом или иным уполномоченным органом;
            - свидетельство о праве собственности на долю в общем имуществе супругов, выданное нотариусом пережившему
            супругу;
            - копия вступившего в законную силу решения суда о признании прав на наследственное имущество;
            - копия вступившего в законную силу решения суда о признании прав пережившего супруга на долю в общем
            имуществе супругов.
            20.7. Оператору предоставляются оригиналы свидетельства о праве на наследство и свидетельства о праве
            собственности на долю в общем имуществе супругов. В случае если

            в представленных свидетельствах указано иное имущество, кроме ЦВ, учитываемых в Системе, допускается
            предоставление их нотариально удостоверенных копий. Указанные в настоящем пункте 20.7 копии решений суда
            должны быть удостоверены подписями судьи, секретаря суда и гербовой печатью суда (если копия состоит из
            нескольких листов, они должны быть пронумерованы, прошиты и скреплены печатью суда), а также иметь сделанную
            судом отметку о дате вступления решения в законную силу.
            В случае предоставления нотариально удостоверенных копий документов они остаются у Оператора.
            20.8. Переход права владения ЦВ к наследнику осуществляется на основании предоставленных наследником
            документов, подтверждающих переход прав на ЦВ в связи со смертью Владельца ЦВ.
            20.9. Наследник – может распоряжаться ЦВ, приобретенными в результате вступления в права наследования при
            условии прохождения регистрации в качестве Пользователя Системы в соответствии с разделом 6 настоящих
            Правил.
            20.10. При переходе права владения ЦВ на имя несовершеннолетнего распоряжение ЦВ осуществляют его законные
            представители.
            20.11. В случае когда свидетельство о праве на наследство уже оформлено, наследники в целях прекращения
            общей
            долевой собственности могут составить соглашение о разделе ЦВ (далее для целей настоящего пункта 20.11–
            «соглашение») в соответствии с Законодательством.
            20.12. Для осуществления перехода права владения ЦВ, находящихся в общей долевой собственности, Оператору
            должны быть предоставлены документы, подтверждающие переход прав на ЦВ в связи со смертью Владельца ЦВ,
            нотариально удостоверенное соглашение и поручение на переход права владения / прием перехода права владения
            ЦВ
            на Адрес наследника. Количество предоставляемых поручений Оператору зависит от количества наследников, в
            пользу которых передаются ЦВ. Количество передаваемых ЦВ в поручении должно быть указано только как целое
            число.
            20.13. В случае если участники долевой собственности не могут договориться между собой о правах на ЦВ и
            условиях раздела и выдела, то возникший спор подлежит разрешению в судебном порядке (ст. 252 ГК РФ).
          </template>
          <template v-else>
            1. General provisions
            1.1. CRYPTOENTER LLC, a legal entity registered in accordance with
            the legislation of the Russian Federation, OG1R2N377000557,1lo3cation address
            : 123112, Moscow, ext. ter. g. Municipal District Presnensky, nab Presnenskaya, 8, building 1, Room. 1P, Com. 41, carries out the activities of an information system operator (hereinafter referred to as the "System"), in which civil transactions and/or Operations involving the transfer of digital currency from one owner to another are carried out (paragraph 3 of Article 14 of the CFA Law), but not limited to them,
            including: for entering, processing, and storing information contained in
            the System's databases.
            1.2. The system is a multi-component automated system and
            uses the Blockchain to reflect records on Transactions and / or Operations.
            1.3. These Rules establish the procedure for using the System, the procedure
            for making Transactions (hereinafter referred to as the" Rules") and are mandatory for Users to review.
            1.4. These Rules are posted on the Operator's official website in
            the Internet information and telecommunications network https://cryptoenter.ru (hereinafter referred to as the" Site") and enter
            into force from the moment they are posted on the Site. The Operator has the right to make changes to
            these Rules and post versions of the Rules with changes and additions (hereinafter referred to as" Amended Versions") on the Site. The amended versions will take effect for
            Users after 15 (fifteen) days from the date of their posting on the Site,
            unless otherwise provided by the Rules.
            1.5. The Rules represent the Operator's offer for the User to join
            these Rules in accordance with Article 428 of the Civil Code of the Russian Federation. 1.6. The User accepts the Operator's offer specified in clause 1.4
            of these Rules by performing specific actions provided
            for in clause 6.1 of these Rules (clause 3 of Article 438 of the Civil Code of the Russian Federation).
            1.7. The User is considered to have fully and unconditionally joined
            these Rules (clause 1 of Article 428 of the Civil Code of the Russian Federation) at the moment when Registration is considered
            completed in accordance with clause 6.1.7. of these Rules. From the moment the User fully and
            unconditionally joins these Rules in accordance with
            this clause, the Rules become effective as a contract in connection with the use
            of the System concluded between the User and the Operator. If
            the User subsequently ceases to agree with any provision of the Rules, the
            User has the right to stop using the System in accordance with the procedure provided for in Section X of these Rules. At the same time, these Rules continue to be
            binding on the User in the part applicable to them, until
            the User is excluded from the Registry.
            1.8. By joining these Rules and starting to use the System, the User
            confirms that:
            - They have fully read the Rules and fully understand their content.
            - The Rules do not deprive the User of the rights normally granted under contracts of this
            type, and do not contain clearly burdensome conditions for the User, which
            the User, based on his reasonably understood interests, would not accept if
            he had the opportunity to participate in determining the content of the Rules.
            1.9. The Operator hereby informs Users that digital currencies (hereinafter referred to as Digital Currencies)
            If they are high-risk assets, their acquisition may result in the loss

            of the deposited funds in full. Before making Transactions with digital assets, you should
            familiarize yourself with the risks associated with their acquisition:
            - Activities and operations with digital currencies are associated with a high level of risk of complete loss of funds and other objects of civil rights transferred in exchange
            for digital currencies (including as a result of value volatility, technical
            failures and/or errors, illegal actions, including theft)
            - Digital currencies are not a means of payment and are not considered to be illegal. provided by the state.
            - Any transactions and operations with digital currencies are carried out by individuals based
            on their own risk assessment and are irreversible.
            - The legal regulation of transactions with digital currencies does not have a uniform approach and the consequences of such transactions may have different legal assessments in
            different states.
            - The technologies used (including distributed registry technology (blockchain),
            other distributed information system and similar technologies) do not
            exclude the risks of technical failures and errors in operation.
            1.10. User registration in the System, making Transactions and/or other actions with digital assets
            in the System is carried out around the clock in automatic mode. The procedure
            for identifying the User and adding funds to the User's balance
            is carried out on working days from 10: 00 to 18: 30 local time of the Operator
            (MSK, UTC +3)
            1.11. The terms used in these Rules with a capital letter have
            the meanings specified in Section 2 of these Rules, if these Rules do not
            contain the following information: otherwise defined.
            1.12. These Rules are set out in Russian.
            1.13. Any difference between the Russian version and the translation into other 2fo.rTeiegrnmlasnagnudagdeesfisnintitoenrpsreted in favor of the Russian version.
            Address - a unique identifier that is associated with information about a digital asset in the Blockchain;
            Algorithm - a program code developed by CRYPTOENTER LLC that
            automates the logical chain of a sequence of steps in a process;
            Authentication data - a set of the User's login, password and / or private key
            required to access the System CRYPTOENTER;
            Authentication data of the Personal Account - the User's login, password and private key required for access to the Personal Account;
            Authentication - confirmation of the User's or
            authorized User's representative's access to the System using
            Authentication data and/or the Personal Account;
            Balance - information about all CVS, Addresses and funds belonging
            to the User;
            Blockchain-distributed banking system. a registry, i.e. a set of databases where the identity
            of the information contained is ensured on the basis of established
            Algorithms (Algorithm).
            Web portal - a web site https://cryptoenter.ru providing User access to
            the System;
            the Owner is the owner of all wheel drive, that is a person included in the Registry, having access to the System
            through the possession of Authentication data private office, which
            allows him to obtain information about wheel drive, it possesses, as well as to dispose of these color through the use of the System;
            the RF civil code - Civil code of the Russian Federation;
            the State authorities , any Federal, regional or municipal organ

            of state power (including the legislative, representative,
            Executive and judicial authorities, local authority), persons holding
            positions established by Law for the direct exercise
            of powers of state authorities, the Bank of Russia, as well as any self-regulatory and other organization engaged in regulating, supervising, licensed,
            registered or other public or quasi-public functions in
            accordance with the Law and the court;
            the Law on personal data - the Federal law dated 27 July 2006 No. 152 - FZ "On personal data";
            The AML / CFT law - Federal law of August 7, 2001 No. 115-FZ "On
            counteraction to legalization (laundering) of proceeds from crime and
            financing of terrorism";
            the CFA Act - the Federal law dated July 31, 2020 No. 259-FZ "On digital
            financial assets digital currency and on amendments to certain
            legislative acts of the Russian Federation";
            the Legislation - the legislation of the Russian Federation;
            the Applicant is a person who wishes to become a User;
            Request and/or Order - bid or offer entered by a User into the System,
            which Express a strong willingness of the User to make a Deal with
            - owned front wheel drive and/or cash;
            Amended shall have the meaning specified in paragraph 1.4 of this regulation; Individual entrepreneur - a natural person registered in
            the manner prescribed by Law as an individual entrepreneur;
            the Incident - an event or combination of events indicating an accomplished, undertaken, or likely implementation of information security threats;
            IP or Information System - a set of databases
            of information and ensure its processing of information technology and technical means in which the Transactions and/or transactions wheel drive;
            ID - the unique identifier to uniquely associate it with the all wheel drive in is;
            the Key Nodes - a set of keys used to sign the blocks,
            the results of the validation of transactions on the Blockchain;
            Confidential information or Confidential data has the meaning
            specified in paragraph 12.8 of this regulation;
            Koin and/or coin - unit accounting front wheel drive User on the System that contains information on all wheel drive;
            an Order to buy - is understood as a proposal for the purchase
            of a certain number of CV at a User-set price.;
            Sales order-means the User's offer to sell
            a certain number of digital assets at the price set by the User;
            Personal Account - a closed section of the System, the use of which requires Registration and further Authentication, intended for
            (1)Transactions and reporting information about the funds transferred
            by the User to the transaction; (2) reflect information about the CV, which
            is owned by the User; (3) communication Systems, including
            electronic documents between the Operator and Users and between
            Users, in accordance with these Rules, to the extent consistent with
            the functionality of the System;
            the Node is a hardware-software complex of performing the functions Node of the blockchain network,
            ensuring the identity of the information contained in the System with
            the use of the procedures for validation, made it (editable
            in it) records,
            NEP - unqualified qualified electronic signature is used in
            accordance with the Federal law dated 06 April 2011 No. 63-FZ "On electronic

            signature";
            the Operator has the meaning specified in paragraph 1.1 of this regulation;
            the Operator of the transaction - the organizer of the trade, other legal entity, another individual,
            the personal law to which the Russian right to provide for the conclusion
            of Transactions with CV and (or) Transactions related to CV, by collecting and matching bi-directional applications for Transactions, or by participating for their own account Transactions as a party to such Transaction in the interests of third parties included in the Registry
            Operator;
            Personal data - any information relating directly or indirectly
            to a specific or identifiable natural person;
            IN the software, on the basis of
            the functioning of the System;
            AML/CFT - combating the legalization (laundering) of incomes obtained in a criminal way, to financing of terrorism and financing the proliferation of weapons of
            mass destruction;
            User - the person that meet these Regulations
            and requirements since Registration with the System in accordance with these
            Rules;
            Rules has the meaning specified in paragraph 1.3 of this regulation;
            Registration - a set of actions in the System provided for by paragraph 6.1
            of this regulation, the result of which is the provision to the Applicant of access to
            the System and the status of the User;
            the Registry , the User registry;
            Registry of transactions , the register of transactions, which leads the Operator;
            the Website has the meaning specified in paragraph 1.4 of this regulation; Transaction - User activity directed on establishment, change or
            termination of civil rights and obligations committed by the use of
            information technology and technical means of the System;
            certificate of the electronic signature Operator - an electronic document that contains
            information about the owner of the key, application key and confirming
            belonging to the owner of the public key. Stored on the key carrier of the Operator and used to confirm the integrity of the System;
            System - has the value specified in paragraph 1.1 of these Rules;
            Messages - has the value specified in paragraph 16.1 of these Rules;
            Token - a unit of accounting for the User's color code in the System containing information about the type of color code;
            Color code - a set of electronic data (digital code or symbols) contained
            in the information system, which are offered and (or) can be accepted as
            a means of payment that is not a monetary unit of the Russian Federation, a monetary unit of a foreign state, and (or) an international monetary or settlement
            unit, and (or) as an investment, and in respect of which there is no person
            obligated to each of them the owner of such electronic data, with the exception
            of the operator and (or) nodes of the information system, which are only required to ensure
            compliance with the procedure for issuing these electronic data and performing
            actions in relation to them to make (change) records in such an information system with its
            rules;
            2.1. In these Rules, unless the context expressly implies otherwise:
            - references to sections and paragraphs are references to sections and paragraphs of these
            Rules, respectively;
            - the table of contents and headings are provided solely for convenience of reference and do not affect the
            interpretation or interpretation of these Rules;
            - words in the singular include the plural and vice versa;
            - words of the same gender include all genders;
            - the time limits specified in days are calculated in calendar days.

            - business day means any day of the week, except (1) weekends; (2)
            non-working holidays in accordance with the Legislation; (3) days declared non-working by the competent state authorities;
            - words and expressions such as" including "and" including", and other similar
            words and expressions, should not be given a restrictive meaning. Phrases
            ending with the words "and others" or "otherwise", or other similar
            words and expressions, are not subject to restrictive interpretation due
            to the relation of the preceding words and expressions to any particular type or
            class, if a broader interpretation is possible;
            - in cases where these Rules refer to Legislation such
            references should be understood as references to Legislation in force as of the date on which the question arises of the application of the Legislation during the term of
            these Rules, including any changes in Legislation after the effective date
            of these Rules.
            3. Terms of use of the system
            3.1. The Operator provides the System to Users for performing the following actions:
            -performing civil operations and/or transactions
            3.2. The Operator is not a party to Transactions made in the System and does not assume the
            rights and obligations of the parties to Transactions, except in cases when it is the User's Agent.
            3.3. Performing the functions of an Operator, the Operator provides the possibility of making
            Transactions in accordance with clause 6.1. of these Rules by collecting and comparing orders and/or Orders.
            3.4. The Operator provides the System for use on the territory of the Russian Federation.
            4. Users
            4.1. Users can be:
            - individuals, citizens of the Russian Federation;
            - individual entrepreneurs;
            - legal entities (with the exception of non-profit organizations) that are properly established and registered in accordance with the Legislation or applicable
            law of a foreign country, and are not in the process of liquidation at the time
            of Registration.
            4.2. A Person becomes a User after Registration in accordance with Section 6 of these Terms.
            5. Rights and obligations
            5.1. Rights and obligations of the Operator
            5.1.1. By providing the System for use in accordance with Section 3 of these
            Rules, and performing the duties assigned to it by Law, the Operator
            has the right to:
            - change the functionality of the System depending on the availability of the technical possibility of
            providing it;
            - request information about Users and documents related to
            Users, necessary for the use of the System by Users, and for
            the exercise of the rights and obligations of the Operator granted and assigned to it in accordance with these Rules and Legislation;
            - refuse Users to use the System in accordance with Section 3

            of these Rules, in case of non-compliance of Users or
            actions or Transactions requested by them with these Rules or Legislation;
            - restrict and suspend Users ' access to the System in cases
            stipulated by these Rules and Legislation, including when
            identifying facts or signs of a violation of the System's security;
            - establish requirements for the software and hardware necessary for
            the User's access to the System and its use;
            - transfer information about Users and Transactions, as well as other actions
            performed using the System, to state bodies in cases
            stipulated by these Rules and / or Legislation;
            - reject User applications for Transactions and other actions
            using the System in cases stipulated by these Rules and / or
            Legislation;
            - unilaterally suspend and reject the User
            's Transactions and other operations with the Central Bank if signs or suspicion of fraud are detected.
            unauthorized actions, misuse of the System, or
            other types of fraud in the field of information technology;
            - deny the User registration in the System.
            - refuse Users to perform the actions specified in section 3, provided that
            as a result of implementing the internal control rules, the Operator suspects
            that the Transaction is being made for the purpose of legalizing (laundering) proceeds from
            crime, financing terrorism or distributing weapons of mass
            destruction;
            - exercise other rights stipulated by these Rules and
            the Legislation.
            5.2. When performing the activities specified in Section 3 of these Rules, the Operator is obliged to:
            - to check the persons undergoing Registration, Users,
            information and documents provided by them for compliance with these Rules and Legislation;
            - ensure that the Owner has access to the System records related to this
            Owner (in particular, records about the CO-owned by him) through the System, as well as the possibility of resuming the Owner's access to the System records
            related to this Owner (in particular, records about the CO-owned by him) no
            later than the day following the day of restoring access to The system, if such access was previously lost to them.
            - ensure the uninterrupted and uninterrupted operation of the System, including
            the availability and proper functioning of redundant (backup) technological and operational means that ensure the uninterrupted and continuous
            operation of the System;
            - to ensure the integrity and reliability of the information about the CV contained in the System records;
            - ensure the correct implementation in the System of the algorithms established by the Operator
            for creating, storing and updating information contained in the Blockchain, and algorithms that
            ensure the identity of the specified information in all databases
            that make up the Blockchain, as well as the impossibility of making changes to the algorithms established by the Operator by other persons;
            - ensure that information about Transactions and participants in such Transactions is stored at least 5
            (five) years from the date of making the relevant Transactions;

            - maintain the Register and make changes to it;
            - provide the User with information, including information about digital assets owned by the User, about Transactions made or made by
            the User in the System, and other information provided for by these
            Rules or Legislation;
            - provide information to state bodies and other persons to the extent and in
            cases stipulated by the Legislation;
            - perform other duties stipulated by these Rules and
            Legislation.
            5.3. Rights and obligations of the User
            5.3.1. The User has the right to:
            - access to the System if the User meets the requirements of these Rules,
            enters the User in the Register and the User complies with these Rules
            - use the System for making Transactions, including the purchase of digital assets, sale of digital assets,
            alienation of digital assets, exchange of digital assets, and other Transactions related to digital Assets;
            - request and receive information about digital assets owned by the User, Transactions made or made by the User in the System, and other information provided for by these Rules or Legislation;
            - contact the Operator with requests related to the operation of the System and technical support of the User;
            - for the Operator to protect the information provided by the User to the Operator; - exercise other rights stipulated by these Rules and
            the Legislation.
            5.4. When using the System, the User must:
            - comply with these Rules;
            - use the System functionality exclusively in accordance with its intended
            purpose;
            - do not attempt to circumvent the technical restrictions set by the System.
            - do not disrupt the System.
            - not to facilitate any actions aimed at violating the restrictions and
            prohibitions imposed by these Rules or Legislation;
            - do not copy or change the source code of the site pages that display
            System elements.
            - not to perform unauthorized access to the Personal Accounts of other Users
            in any way, including by selecting or entering a password, as well as not
            to attempt such access;
            - provide the Operator with complete and reliable information necessary for
            providing the User with access to the System and using
            the System by the User, including the User in the Registry, as well as for to verify the compliance
            of Transactions and actions performed by the User in the System
            with legal requirements and to ensure compliance with legal requirements
            by the Operator;
            - notify the Operator of changes in the User's data, other information
            necessary to provide the User with access to the System and use
            of the System by the User, as well as information included in the Register;
            - respond to requests from the Operator and provide it with information the information specified in the request,
            in cases stipulated by these Rules and Legislation;
            - install software and hardware tools necessary for using the System
            and protecting information, and maintain their operability;
            - immediately inform the Operator about System failures and other
            incidents that the User has become aware of, and in case of this, immediately
            suspend use of the System. Systems;

            - do not transfer your Personal Account Authentication data to third parties. - not to use the System for making transactions aimed at legalizing (laundering) proceeds from crime, financing terrorism,
            financing the proliferation of weapons of mass destruction, carrying
            out corruption activities, other activities prohibited by law, as
            well as for making other transactions and actions prohibited by law;
            - perform other duties stipulated by these Rules and
            the Legislation.
            5.5. The User is obliged not to post or distribute in the IP:
            - information that is prohibited from being distributed in the Russian Federation; - information that is distributed in violation of the Law;
            - insult and threaten other Users and/or employees of the System;
            - use profanity in any form;
            - speak out to incite ethnic hatred, promote violence
            in any form, and offend the religious feelings of other Users;
            - distribute links to external resources in any form.
            - constantly publish the same information or repeat the same
            phrase in the text;
            - information distributed in violation of the rights and legitimate interests of third parties,
            including copyright, related rights and other rights to the results of intellectual activity and means of individualization;
            - false, misleading, derogatory or offensive information;
            - unfair and unreliable advertising, as well as advertising distributed
            in violation of the Law;
            - other information, the distribution of which is restricted by law in the Russian Federation
            .
            6. Registration and Access
            6.1. The registration procedure includes the following sequence of actions:
            6.1.1. familiarization of the Applicant with these Rules, other mandatory
            policies of the Operator, the link to which is posted on the login page;
            6.1.2. confirmation by the Applicant that he has read these Rules,
            including the procedure for processing Personal Data specified in section 12 of these Rules, other mandatory policies of the Operator, agrees with these
            Rules, including the procedure for processing Personal Data specified in
            section 12 of these Rules, other mandatory policies of the Operator, and understands that from the moment provided for in paragraph 6.1.8 of these Rules, in accordance with
            paragraph 1.7 of these Rules, it will be considered to have fully and unconditionally acceded to these Rules, at the same time by affixing
            a consent sign in the in the fields of the displayed on-screen form and/or by affixing the Applicant's electronic signature under the electronic document that will be displayed on
            the login page or to which a link will be placed on the login page
            ;If the Applicant is unable or unwilling to confirm any of the circumstances specified in this clause 6.1.2 of these Rules, the Applicant must immediately leave the login page;
            6.1.3. User registration in the Operator's Information System is carried
            out through the registration screen form located on the Operator's Web Portal
            on the Internet at a permanent address https://cryptoenter.ru/registration by entering the user's email address.

            6.1.4. Confirmation of the validity and ownership of the User's email address
            is carried out by sending a message to the specified email address
            containing a link to the on-screen registration completion form.
            6.1.5. The registration procedure is performed through the on-screen form for entering the password
            and clicking the "Confirm registration" button.
            6.1.6. Upon completion of registration, the User of the Operator's system creates a private
            key for accessing and performing all operations in the System, which is stored by the user.
            6.1.7. User registration is considered completed after clicking
            the "Save and log in" button
            6.1.8. In the future, the User is authenticated in the System and connected
            to the System using Authentication Data,
            a combination of login, password and private key to access the System through the on- screen form
            7lo.cRategdisatetrhe permanent address https://cryptoenter.ru/login.
            7.1. The Operator maintains the Register. The following information is included in the Register for each User
            :
            - information about the User.
            - information required for Authentication.
            7.2. Documents confirming the information to be included in the Register and specified in clause 7.1 of these Rules are stored for the entire period of
            the User's presence in the Register, as well as for 5 (five) years after the User is excluded from the Register,
            unless a longer storage period is established by Law.
            7.3. Documents confirming the information to be included in the Register and
            specified in clause 7.1 of these Rules, depending on the type of information that they confirm, are stored by the Operator in electronic form (including in the form
            of electronic copies of documents submitted on paper) in the subsystems
            of the System, as well as in other information systems, which are part of the System, in particular, ensuring the functioning of the System. The operator ensures
            the integrity and immutability of these documents, the ability to search and upload information from such documents to external storage media on a specific date.
            8. Procedure for performing operations and accounting in the system
            8.1. The following types of operations are available to identified Users of the System in their Personal Account
            :
            - sale of digital currency
            - purchase of digital currency
            - digital transfer between users
            - exchange of digital currencies between users
            8.2. The user's operation is performed through the System's Personal Account.
            8.3. The User sends an order for performing an operation to the System by
            filling out the on-screen form corresponding to the type of operation in the Personal Account section.
            8.4. The user confirms the order through the on-screen form
            corresponding to the type of operation by pressing the appropriate button, entering the password and
            private key.
            8.5. The User's order is executed by the System in automatic mode according
            to the system algorithms specified in Section 11 of this Agreement.
            8.6. The result of processing and executing the user's order is displayed in
            the User's Personal Account.
            8.7. The report on the client's operations is displayed in the corresponding section of the Personal
            Account. Documentary confirmation and a report on executed orders and
            operations performed are provided by the System at the User's request by

            sending a PDF file in electronic form to the email address specified during registration.
            9. System Usage fee
            9.1. Users pay the Operator the following types of rewards:
            - Trading Commission - the Operator's remuneration for making a Transaction using the System;
            - Withdrawal Commission - the Operator's remuneration for conducting and/or executing
            the operation of withdrawing digital currency and / or funds from the System.
            9.2. The specific amount and procedure for payment of the Operator's remuneration will be
            determined by the tariffs accepted by the Operator. Information about
            the Operator's current tariffs is available to Users in the "Commission and Limits" section on
            the Operator's website.
            9.3. The Operator's remuneration is paid in Russian rubles. In
            the System interface elements, to simplify information perception and calculations, Operator Fees, Commissions, and Limits are indicated in COL and / or cash, but are made
            in rubles.
            9.4. The Operator's remuneration is paid by the User at the time of
            the Transaction and/or withdrawal of digital currency and / or funds.
            10. Information protection
            10.1. The Operator, in accordance with regulatory acts and with regular
            assessment, ensures the protection of information received, prepared,
            processed, transmitted and stored in the automated systems used by it for the operation of the System, including:
            - information about Users, including their Personal Data and/or Personal
            Data of their representatives;
            - information on the basis of which the Central Bank applies, including
            information contained in electronic messages-instructions to enter or
            change a record about the Central Bank in the System at the direction of Users;
            - information about all completed Transactions, participants in such Transactions. Transactions and other
            Confidential Information;
            - information about the Addresses of the blockchain, the Addresses of the Node (s) and the Operator's electronic signature certificate.
            10.2. Information protection and ensuring the operational reliability of the System
            are provided by the Operator by applying, among other things, the following measures: - using multi-factor Authentication when accessing the System;
            - ensuring the confidentiality of information about Transactions through the use
            of a verification mechanism by Nodes;
            - mutual authentication when exchanging messages between any IP components;
            - encryption of the data exchange channel between Nodes, the use of a protocol
            for establishing a secure connection between Nodes;
            - monitoring the integrity of messages, identifying Users who make Transactions, their authority to make Transactions;
            - applying organizational and technical measures that ensure the processing
            of Incidents related to unauthorized access to cryptographic keys
            during their formation, use and storage, including in accordance with
            the requirements of technical documentation for cryptographic
            information protection tools;
            - implementation of an incident monitoring system and regulation of measures to respond to
            Incidents;
            - analysis of network interaction traffic between Nodes in order to ensure

            the continuity of making (changing) records in the Blockchain; ensuring blocking of potentially dangerous records in the Blockchain that can lead to a change
            in the sequence of records in the Blockchain, providing analysis and control of the algorithm (s)
            that (2) the identity of information contained in all
            databases that make up the Blockchain, aimed at ensuring the impossibility
            of implementing computer attacks, including from Nodes by controlling
            these algorithms;
            - the use of cryptographic algorithms, taking into account the assessment of their resistance to current
            threats to information security, to prevent attacks aimed at
            cryptographic algorithms and protocols;
            - the use of secure program code and protocols by Nodes, taking into account current security threats and applied information technologies, including
            the implementation of a denial-of-service protection system with
            the ability to filter
            - restriction of access rights to data for employees, IP administrators and IP technology accounts, including using encryption at the stages of
            information storage and transmission.
            10.3. In relation to the used Node Keys and the Operator's Electronic Signature Certificate
            , the following is additionally applied:
            - specialized software solutions for storing
            Node process keys in virtualization environments;
            - specialized hardware and software certified on the territory of the Russian Federation
            for
            working with cryptographic information protection
            tools for storing and using the Operator's Electronic Signature Certificate.
            10.4. Operational reliability is provided by the Operator in accordance with
            the following conditions:
            - maintaining the System's operability, eliminating System failures caused by the
            Operator within a reasonable time, but not more
            than within three business days;
            - providing technical support to the User on issues related to working with the System.
            Technical support is provided on working days from 10: 00 to 18: 00 Moscow time.
            Contact information for technical support
            is available on the Website.
            10.5. The Operator applies measures to protect information and ensure operational
            reliability in accordance with the Legislation and internal
            documents of the Operator.
            10.6. The Operator applies the following measures to ensure the integrity of information
            in
            the IP and achieve a high level of availability:
            - duplication of program code execution environments;
            - use of a specialized protocol to achieve consensus between
            Nodes;
            - storage and processing of data about the owners of digital assets in the form of a block chain (Blockchain) to
            achieve control over the integrity of the transaction history using
            a specialized Hyperledger platform;
            -1u1s.eAolgf omreitchmansisomfsthfoer sayusttoemmatic verification of Transaction correctness by Nodes. 11.1. Use of Algorithms in the System
            11.1.1. The identity of information contained in the System is ensured on the basis
            of established Algorithms.
            11.1.2. The algorithms of the System must be placed on the Node. Identity of information
            on different Nodes of a distributed System is achieved by consensus of Nodes.
            11.1.3 Types Of System Algorithms:
            - Algorithms for managing the CV in the System;
            - Algorithms for controlling the movement of digital vehicles in the System;

            - Algorithm for forming the current cost of digital assets
            - Algorithms for exchanging digital assets in the System.
            - Algorithms for matching User requests in the System.
            - Algorithms for accounting for the User's CV in the System.
            - Algorithms for accounting for User funds in the System.
            11.2. Making changes to the System Algorithm programs.
            11.2.1. The Operator has the right to unilaterally make changes to the System's Algorithm programs.
            11.2.2. Making changes to the System Algorithm programs is aimed, inter alia, at expanding its functionality, maintaining and improving its
            security, operational reliability and operability. The Operator ensures
            that making changes to the System Algorithm program does not affect the rights of Users registered in the System prior to making the corresponding
            changes, including not changing the conditions of their access to the System and the right to dispose
            of their own digital assets, does not change the terms of Transactions and other actions with digital assets
            initiated,performed or executed prior to making appropriate
            changes.
            11.2.3. Before making changes to the System Algorithm program, the Operator checks and tests the corresponding changes for resistance to information
            security threats, operational reliability, comparability with other System elements,
            and impact on the use of the System by Users.
            11.2.4. The Operator informs Users about changes made to the
            System Algorithm program, if these changes affect the use of the System
            by Users, in accordance with the procedure provided for in Section 16 of these Rules, by
            placing an appropriate ad on the Site or in any other way at
            the Operator's choice. In other cases, the User is not informed about changes made to the
            System Algorithm program.
            12. Personal and Confidential Data
            12.1. The Operator processes Users ' Personal Data that
            is necessary for performing actions in the System provided for in Section 3 of these Rules, in accordance with the provisions of these Rules and
            legal requirements, with the User's consent to the processing of personal data (hereinafter
            referred to as the User's Consent to the OPd), which is an integral part of these rules 12.2. The Operator processes Personal Data:
            - Users who are individuals and sole proprietors, in order to provide them
            with access to the System for making Transactions with the Central Bank. At the same time, the basis for processing
            Personal Data of this category of persons is paragraph 5 of part 1 of Article 6 of the Law on
            Personal Data;
            - authorized representatives of Users who are legal entities,
            in order to provide Users with access to the System for making Transactions with the Central Bank.
            At the same time, Users guarantee and assure that they have legal grounds, taking into account the provisions of paragraph 12.5 of these Rules, for transferring Personal Data of the specified category of persons to the Operator and their further processing by the Operator for the
            above purposes.
            12.3. The Operator processes Personal Data to the extent necessary for
            performing actions in the System provided for in Section 3 of these Rules.
            12.4. During the processing of Personal Data, the Operator performs actions with them by

            all available methods (including automated and
            non-automated processing), including: collection, systematization,
            accumulation,storage,
            clarification (updating, modification), use, transfer (provision,access),
            depersonalization, blocking and destruction.
            12.5. Personal data of authorized representatives of Users who are
            legal entities or authorized representatives of other Users
            are transferred to the Operator by the respective Users. Operators of Personal
            Data in relation to the Personal Data of such persons and responsible persons in
            relation to the processing of Personal Data of such persons are the Users whose
            representatives these persons are.
            12.6. The Operator and Users to whom
            Personal Data is transferred in accordance with these Rules are obliged to ensure the
            confidentiality
            of the processed Personal Data and the fulfillment of other obligations
            stipulated by the Law on Personal Data.
            12.7. In the part not regulated by this section of the Rules, the processing of Personal
            Data is carried out in accordance with the provisions of the Law on Personal Data.
            12.8. Within the framework of performing actions in the System provided by the
            Operator in
            accordance with Section 3 of these Rules, "Confidential Data" and/or
            "Confidential Information" is information:
            - about Transactions made by specific Users.
            - about settlements for these Transactions.
            - other information received by Users during the execution of Transactions;
            - about User's banking details;
            - Personal data of Users who are Individual
            entrepreneurs;
            - Personal data of authorized User representatives.
            12.9. Confidential information may be provided by the Operator to:
            - to a specific User for Transactions in which he participates as a party or Authorized User, in relation to a specific User or
            Users participating as a party or Authorized User in
            the same Transactions;
            - with the consent of Users to the persons specified in such consent;
            - To the Bank for organizing monetary settlements in the System using monetary funds;
            - state bodies and other persons in cases stipulated
            by the Legislation;
            - in other cases related to the performance by the Operator of its rights and obligations stipulated by these Rules and the Legislation.
            12.10. Users are required to keep
            their Personal Account Authentication Data confidential and not disclose it to third parties. If the
            Personal Account Authentication Data becomes known to third parties, Users are required
            to immediately change the Personal Account Authentication Data.
            12.11. Users are not entitled to transfer to third parties, without the written consent of the Operator
            , any information that has become known to them in connection with the use of the System and
            the implementation of these Rules, except in cases related to the performance of the duties assigned
            to Users by Law and with the protection of Users
            ' interests in accordance with the procedure established by Law.
            12.12. The obligations provided for in this section are valid for
            an unlimited period of time.
            13. Taxation
            13.1. Users independently pay taxes and fees and perform other
            duties stipulated by the tax Legislation related to making
            Transactions and other actions using the System.
            13.2. The Operator pays taxes and fees independently and performs other duties stipulated by the tax legislation and related to the Operator's activities.

            14. Intellectual property
            14.1. The exclusive right to the Software, including the source text, object code, preparatory materials obtained during software development,
            visual displays generated by the Software, other Software elements, the right to the Software name, the right to
            information contained in the Software, belongs to the Operator (Certificate of State registration of computer programs no. 2023660009).
            14.2. The Operator provides Users with a simple, royalty-free, non-exclusive license to use the Software for the entire duration of the exclusive right to use the Software in
            the Russian Federation.
            14.3. The User has the right to use the Software exclusively for the purpose of using
            the System's functionality in their personal or commercial activities. The User does not have the right to distribute copies of the Software in any way, make it available
            to the public, process (modify) it, decompile
            or modify the software components of the System, or use the Software in any other way.
            in a way that is not related to the purpose of using the Software provided for in this paragraph.
            15. Interaction with government agencies
            15.1. As part of the Operator's interaction with government agencies and other persons: - not later than the working day following the day of receipt of the relevant request,
            on the basis of a court act that has entered into legal force, an enforcement document, including a bailiff's order, acts of other bodies and
            officials in the exercise of their functions provided
            for by Law, or
            a certificate of entitlement issued in accordance with the procedure provided for by Law
            15.2. The Operator provides the information
            contained in the System's records about the COL belonging to their Owner, which provides for the transfer of a certain type of COL in the order of universal legal succession
            .
            - at the request of the court;
            - according to the requirements of the federal executive body that takes measures to counteract the legalization (laundering) of proceeds from crime,
            the financing of terrorism and the financing of the proliferation of weapons of mass destruction, enforcement agencies of the Russian Federation, tax
            authorities, other bodies and officials in cases provided
            for by legislative acts on their activities, as well as on the basis of a court decision decisions in
            cases where such information is necessary for them to perform their functions provided for by law;
            - with the consent of the head of the investigative body - at the request
            of the preliminary investigation bodies in cases under their proceedings;
            - on the basis of a court decision-to officials of bodies authorized
            to carry out operational search activities, when performing their functions of detecting, preventing and suppressing crimes upon their requests sent
            to the court in accordance with the procedure provided for in Article 9 of Federal Law No. 144-FZ of August 12, 1995 "On Operational Search Activities", if there is information about the signs
            of crimes being prepared, committed or committed, as well as about the persons preparing, committing or committing them, if there is not sufficient data to
            resolve the issue of initiating a criminal case;
            - on requests sent by authorized persons in accordance with
            the anti-corruption legislation of the Russian Federation;
            15.3
            . The Operator's
            interaction with state bodies and other persons in other
            cases is regulated by law.

            16. Information interaction
            16.1. All notifications, notices, messages (hereinafter referred to as "Messages") sent by the Operator in connection with these Rules and the use of the System may be sent by the Operator to the User in one of the following ways:
            - using the System's functionality by sending a message through your Personal Account, with the content of such a Message recorded in the Message (including using automatic
            marking by the System's software tools)
            , including in the form of an electronic document and (or) a scanned copy of the document;
            - delivery by express, courier delivery or by sending a registered letter
            - delivery by express, courier delivery, or by sending a registered letter with delivery notification to the legal address of the User who is
            a legal entity or Individual Entrepreneur, indicated in the Unified
            State Register of Legal Entities or in the Unified State Register
            of Individual Entrepreneurs, respectively;
            - by sending a registered letter with delivery notification to the legal address of the User who is a legal entity or Individual Entrepreneur, indicated in the Unified State Register of Legal Entities or in the Unified State Register of Individual Entrepreneurs, respectively; - by sending a registered letter with delivery notification to the legal address of the User who is a legal Entity or Individual Entrepreneur, indicated in the Unified State Register of Legal Entities or in the Unified State Register of Individual Entrepreneurs, respectively. Messages (except for Messages containing information classified as a bank secret, commercial secret, and / or containing
            Personal Data) To the User to the e-mail address specified in the Personal
            Account, from the Operator's e-mail address, including in the form of an electronic document and/or a scanned copy of the document.
            16.2. All Messages sent by the User to the Operator in connection with these
            Rules and the use of the System may be sent by the User to the Operator
            in one of the following ways:
            - using your Personal Account, including in the form of an electronic document and / or a scanned copy of the document;
            - by courier delivery or by sending a registered letter with a notification of
            delivery to the Operator's address indicated on the Site;
            - by sending a Message to the Operator to the Operator's e-mail address
            , including in the form of an electronic document and / or a scanned copy of the document.
            16.3. The message is considered delivered (delivered) to the recipient:
            - in case of sending via the Personal Account-no later than the next
            business day after sending by the sender;
            - in case of delivery by express-on the date of actual delivery of the Message to the recipient,
            confirmed by the signature of the recipient or an authorized representative of the recipient
            (against receipt), or on the date of confirmation of non-delivery of the message due to the absence
            of the recipient at the address indicated in the Message;
            - in the case of courier delivery – on the date specified in the confirmation of delivery

            of the shipment to the recipient, available to the sender, or on the date of confirmation of non-delivery of the Message due to the absence of the recipient at the address indicated in the Message
            ;
            - in the case of sending a registered letter with a delivery notification – on the date specified in
            the delivery notification, or on the date when the recipient refuses to receive the sent 16.4. The Message is considered delivered even if it was received
            Message when the postal service organization registers this fact, or on the date on by the relevant person, but due to circumstances that depend on him, it was not
            which the Message was not delivered when the postal service organization registers delivered
            tohihsifmacotr the person did not read it.
            16.5. The Operator is not responsible for the User's failure to receive or untimely ;
            receipt
            - in the case of sending an e-mail to mail – on the date of receipt of the delivery report
            of Messages sent by the Operator in one or more
            automatically sent by the recipient's mail server, or after 24 hours
            ways provided for in this Agreement. section 16, for reasons that depend on
            from the date of sending the Message, whichever is earlier.
            the User. Examples of reasons that depend on the User, among other things, are
            cases of failure or improper provision by the User of the possibility
            of receiving Messages in the ways provided for in this section 16, failures in
            the operation of communication systems,power supply, software, equipment on the User's side, failure or untimely performance
            of actions by the User to timely and properly receive Messages
            sent by the Operator.
            16.6. In case of changes in the User's contact or other data, the User
            is obliged to inform the Operator about this before the changes take effect or within 2 (two) business days from the moment such changes occur. A user
            who does not report the changes bears the risk of adverse
            consequences caused by this.
            16.7. The Operator notifies Users of changes in its contact details,
            including the email address from which Messages can be sent
            to Users or to which Messages can be sent by Users, by
            posting updated information on the Site. Such posting of information is considered a proper notification to Users of such changes.
            16.8. The User bears the risk of adverse consequences due to non-receipt
            of Messages due to the absence of the User or his authorized representative
            at the legal address of the User who is a legal entity or
            Individual Entrepreneur, indicated in the Unified State Register
            of Legal Entities or in the Unified State Register of Individual
            Entrepreneurs, respectively.
            17. Dispute Resolution
            Procedure 17.1. In the event of disputes and disagreements arising out
            of or in connection with these Rules, the parties are obliged to take all measures to resolve them through
            negotiations.
            17.2. The party initiating the dispute resolution procedure is obliged to send
            a claim to the other party stating the essence of the differences that have arisen.
            17.3. The party that received the claim must provide a reasoned response within 30 (thirty) days from the date of its
            receipt.
            17.4. If the dispute is not resolved in accordance with the procedure provided for in paragraphs 17.1-17.3 of these
            Rules, or the party that received the claim has not provided a reasoned
            response to it within the time period specified in paragraph 17.3 of these Rules, then the claim
            procedure is considered to be observed, and the party has the right to apply to the court after the expiration
            of the time period provided for in paragraph 17.3 of these Rules. Rules.
            17.5. Unless otherwise provided by Law, disputes arising out of
            or in connection with these Terms and Conditions are settled by a justice of the Peace, a district court or an
            arbitration court at the Operator's location.

            18. Information Disclosure
            18.1. Information about the Operator, these Rules, tariffs for using the System set by the Operator, and other documents related to the use of the System
            by the User are disclosed by the Operator on the Site.
            18.2. The information and documents specified in clause 18.1 of these Rules are updated
            by the Operator as changes are made to them. Users are required to independently track changes made to this information and documents.
            19. Conflict of Interests
            19.1. The Operator takes measures established by its internal documents aimed at preventing conflicts of interest when securing
            Transactions and other actions using the System.
            20. Inheritance
            20.1. If the Operator receives documents confirming the Owner
            's death, the Owner's digital assets are blocked until the documents confirming the transfer of rights
            to the digital Asset to another Owner(s) are presented.
            20.2. The Central Bank is blocked on the basis of one of the following documents:
            - a certificate and / or request from a notary, executor of a will, or an official authorized by law to perform notarial acts;
            20. 3. Upon receipt of the request, the Operator provides the notary, executor of the will
            or an official authorized by law to perform notarial
            actions, a report on transactions on blocking the Owner's Central Bank account and/or an extract
            of the Owner's Balance Sheet.
            20.4. The Operator, at the request of the notary, issues him an extract of the Owner's
            Balance. If
            there is a special indication in this request, this statement can
            be received by the heir.
            20.5. If the CVS are bequeathed to an organization or a person who is not a relative
            of the Owner, instead of a death certificate, a certificate or
            an extract from the act book of the city or district civil registration department may be
            provided
            . This certificate confirming the fact of death of the person who bequeathed the CV
            must
            be signed by an authorized person and sealed with the official seal of the relevant
            civil registration authority.
            20.6. Documents confirming the transfer of rights to a digital asset due to the Owner's
            death
            include:
            - a certificate of inheritance rights issued by a notary or other authorized
            body;
            - a certificate of ownership of a share in the common property of the spouses issued
            by a notary to the surviving spouse;
            - a copy of the court decision on recognition of rights to inherited property that has
            entered into legal force
            ;
            - a copy of the court decision that has entered into legal force recognizing the rights of
            the surviving
            spouse to a share in the common property of the spouses.
            20.7. The Operator is provided with the originals of the certificate of inheritance rights
            and
            in the submitted certificates, other property is indicated, except for the CVS registered
            certificates of ownership of a share in the common property of the spouses. If in the
            System, and notarized copies of them are allowed.
            The copies of court decisions specified in this clause 20.7 must be certified
            by the signatures of the judge, the registrar of the court and the stamp of the court (if the copy consists of
            several sheets, they must be numbered, stitched and sealed with the seal
            of the court), and also have a note made by the court on the date of entry into force of

            the decision
            .
            If notarized copies of documents are provided, they will remain with the
            Operator.
            20.8. The transfer of the right of ownership of the COL to the heir is carried out on the basis
            of documents provided by the heir confirming the transfer of rights to the COL in connection
            with the death of the Owner of the COL.
            20.9. The heir – may dispose of the COL acquired as a result of entering into
            the rights of inheritance, provided that he / she has registered as
            a System User in accordance with Section 6 of these Rules.
            20.10. Upon transfer of the right to own a digital asset in the name of a minor, the digital asset is disposed
            of by the minor's legal representatives.
            20.11. In the event that the certificate of inheritance rights has already been issued, the heirs
            may draw up an agreement on
            the division of the Central Bank (hereinafter referred to as the "agreement" for the purposes of this clause 20.11) in accordance with the Law in order to terminate the common shared ownership
            .
            20.12. In order to transfer ownership rights to co-
            owned vehicles, the Operator must be provided with documents confirming the
            transfer of ownership rights to co-owned vehicles due to the death of the Owner of the Co-owned vehicle, a notarized
            agreement and an order for the transfer of ownership rights / acceptance
            of the transfer of ownership rights to the Address of the heir. The number of orders provided to the Operator depends on
            the number of heirs in favor of whom the color codes are transferred. The number of transmitted color
            codes in the order must be specified only as an integer.
            20.13. If the participants in shared ownership cannot agree among
            themselves on the rights to the Central Bank and the terms of division and allotment, then the dispute that has arisen is subject
            to resolution in court (Article 252 of the Civil Code of the Russian Federation).
          </template>
        </p>
      </div>
      <div class="Registration-Line term">
        <input
          id="term"
          class=""
          type="checkbox"
          name="term"
          :checked="termKey"
        >
        <label
          class="Registration-Checkbox"
          for="term"
        />
        <p class="term_margin">
          {{ $t('regp_term_of_use_label') }}
        </p>
      </div>
      <div class="Global_InfoText Global_InfoText_Reg">
        <p
          class="Global_Text Global_Text_FontSize_S Global_Text_Color_Standart Registration_FontWidth"
        >
          <!--          CryptoEnter OÜ AML Policy It is the policy of Cryptoenter to prohibit and actively prevent money laundering-->
          <!--          and-->
          <!--          any activity that facilitates money laundering or the financing of terrorist or criminal activities. We will-->
          <!--          comply with all applicable requirements and regulations. Money laundering is generally defined as engaging in-->
          <!--          acts designed to conceal or disguise the true origins of criminally derived proceeds so that the proceeds-->
          <!--          appear-->
          <!--          to have derived from legitimate origins or constitute legitimate assets. Cryptoenter has zero-tolerance policy-->
          <!--          for money laundering activities. Our AML/CFT policies, procedures and internal controls are designed to ensure-->
          <!--          compliance with all applicable regulations and will be reviewed and updated on a regular basis to ensure-->
          <!--          appropriate policies, procedures and internal controls are in place to account for both changes in regulations-->
          <!--          and changes in our business. This AML Policy aims to prevent the use of Cryptoenter’s services by criminal-->
          <!--          entities for the purposes of money laundering, financing of terrorism, fraud, corruption, or any other-->
          <!--          criminal-->
          <!--          activity. To this end, the company has established strict money laundering detection and prevention policy.-->
          <!--          Also, to counter money laundering, the company has implemented a sophisticated electronic customer-->
          <!--          verification-->
          <!--          system and introduced detailed statistics logging for all customer transactions. Furthermore, Cryptoenter,-->
          <!--          under-->
          <!--          no circumstances, accepts cash from or pays cash to its customers. The company reserves the right to suspend-->
          <!--          any-->
          <!--          transaction of its customer that may be considered illegal or, in Cryptoenter employees’ opinion, may be-->
          <!--          related-->
          <!--          to money laundering. The principal requirements, obligations and penalties, on which Cryptoenter AML policy is-->
          <!--          based, are contained in: – The Proceeds of Crime Act 2002 (POCA), as amended by the: Serious Organised Crime-->
          <!--          and-->
          <!--          Police Act 2005 (SOCPA); and the Proceeds of Crime Act (Amendment) Regulations 2007; – The Terrorism Act 2000,-->
          <!--          as amended by the: The Anti Terrorism, Crime & Security Act 2001; and the Terrorism Act (Amendment)-->
          <!--          Regulations-->
          <!--          2007; – The Terrorism Act 2006; – The Bribery Act 2010; – The Money Laundering Regulations 2007, transposing-->
          <!--          the-->
          <!--          requirements of the EU’s Third Money Laundering Directive; – The Joint Money Laundering Steering Group (JMLSG)-->
          <!--          Guidance for the UK Financial Sector on the prevention of money laundering/combating terrorist fi nancing. We-->
          <!--          have a designated Money Laundering Reporting Officer (“MLRO”). The MLRO has full responsibility for our-->
          <!--          AML/CFT-->
          <!--          program. The duties of the MLRO will include monitoring our compliance with AML/CFT obligations, overseeing-->
          <!--          communication and training for employees and overseeing software modifications to ensure they comply with-->
          <!--          AML/CFT obligations. The MLRO will also ensure that we keep and maintain all of the required AML/CFT records.-->
          <!--          2-->
          <!--          KYC - identification and verification of new customers, deposits, transfers and withdrawals In order to verify-->
          <!--          the account (confirm the customers’ identity), the user must provide the following information: Full name;-->
          <!--          Date-->
          <!--          of birth; Country of residence; Phone number, which Cryptoenter will send test message or call to verify the-->
          <!--          identity; Email with which account is registered. This is followed by verification of the customer’s phone-->
          <!--          number in all kinds of databases, such as yellow/white pages. In case of suspicion, our employee will also-->
          <!--          call-->
          <!--          the bank and request a bank clerk to contact the customer to verify that the user did use his or her-->
          <!--          debit/credit card to purchase bitcoins on Cryptoenter; Verified proof of ID (Color copy of Passport, or-->
          <!--          National-->
          <!--          ID, or International Driving License) & Verified proof of residence (Utility Bill, Bank Statement with-->
          <!--          transactions, Bank reference letter no more than 3 months old). Cryptoenter will perform AML checks within the-->
          <!--          following but not limited databases: the Office of Foreign Assets Control (OFAC), Her Majesty’s Treasury-->
          <!--          Department – UK (HMT), European Union sanctions (EU), United Nations sanctions (UN). Cryptoenter will be-->
          <!--          monitoring customer transactions on a daily basis in real-time for risk within above-mentioned databases.-->
          <!--          Furthermore, all existing clients will be screened against the updated databases every day; The usage of-->
          <!--          Cryptoenter is prohibited for the citizens of the following countries: Afghanistan, Algeria, Angola, Antigua-->
          <!--          and-->
          <!--          Barbuda, Bangladesh, Bolivia, Bosnia and Herzegovina, Botswana, Cambodia, China, Cuba, Democratic Republic of-->
          <!--          the Congo, Ecuador, Egypt, Eritrea, Ethiopia, Gambia, Ghana, Guinea-Bissau, Guyana, Indonesia, Iran, Iraq,-->
          <!--          Ivory-->
          <!--          Coast, Japan, Kyrgyzstan, Laos, Lebanon, Lesotho, Liberia, Libya, Macedonia, Malaysia, Mali, Morocco, Myanmar,-->
          <!--          Namibia, Nepal, Niger, Nigeria, North Korea, Pakistan, Papua New Guinea, Qatar, Republic of the Congo,-->
          <!--          Senegal,-->
          <!--          Sierra Leone, Somalia, South Sudan, Sri Lanka, Sudan, Syria, Tanzania, Togo, Trinidad and Tobago, Uganda,-->
          <!--          United-->
          <!--          States, Vanuatu, Vietnam, Yemen, Zambia, Zimbabwe If a potential or existing customer either refuses to-->
          <!--          provide-->
          <!--          the information described above when requested, or appears to have intentionally provided misleading-->
          <!--          information, we will deactivate the account and, after considering the risks involved, consider closing any-->
          <!--          existing account. In either case, our MLRO will be notified so that we can determine whether we should report-->
          <!--          the situation to the authorities. If the amount of funds deposited in/withdrawn from an account every month-->
          <!--          exceeds EUR 5,000 a video is required, of the customer showing his or her passport or driver’s license, as-->
          <!--          well-->
          <!--          as the front of his or her bankcard so that the first and last name can be read easily. The card number should-->
          <!--          be covered for security reasons; If the amount of funds deposited in/withdrawn from an account exceeds EUR-->
          <!--          50,000, Cryptoenter may request the account user to provide some additional information, including to answer-->
          <!--          questions about how he or she developed an interest in bitcoins, the purpose of his or her transactions, his-->
          <!--          or-->
          <!--          her trading strategy, the user’s biography, and the origins of the funds operated on the Website of the-->
          <!--          Service,-->
          <!--          including documentary evidence (e.g., a bank statement showing the receipt of a salary, a contract for the-->
          <!--          sale-->
          <!--          of an interest in a business, etc.) Based on the risk, and to the extent reasonable and practicable, we will-->
          <!--          ensure that we have a reasonable belief that we know the true identity of our customers by using risk-based-->
          <!--          procedures to verify and document the accuracy of the information we get about our customers, but in any case-->
          <!--          complying with the statutory requirements. The MLRO will analyse the information we obtain to determine-->
          <!--          whether-->
          <!--          the information is sufficient to form a reasonable belief that we know the true identity of the customer-->
          <!--          (e.g.,-->
          <!--          whether the information is logical or contains inconsistencies). We will verify customer identity through-->
          <!--          documentary means, non- documentary means or both. We will monitor account activity for unusual size, volume,-->
          <!--          pattern or type of transactions, taking into account risk factors and red flags that are appropriate to our-->
          <!--          business. The MLRO or his or her designee will be responsible for this monitoring, will review any activity-->
          <!--          that-->
          <!--          our monitoring system detects, will determine whether any additional steps are required, will document when-->
          <!--          and-->
          <!--          how this monitoring is carried out, and will report suspicious activities to the appropriate authorities. The-->
          <!--          MLRO or his or her designee will conduct an appropriate investigation and review relevant information from-->
          <!--          internal or third-party sources before the authorities are notified. We monitor possible insufficient or-->
          <!--          suspicious information provided by the customer, particularly when the customer: – Provides unusual or-->
          <!--          suspicious identification documents that cannot be readily verified. – Reluctant to provide complete-->
          <!--          information-->
          <!--          about nature and purpose of transaction, anticipated account activity etc. – Refuses to identify a legitimate-->
          <!--          source for funds or information is false, misleading or substantially incorrect. – Background is questionable-->
          <!--          or-->
          <!--          differs from expectations based on business activities.-->
          <template v-if="$i18n.locale === 'ru'">
            В соответствии со статьями 23, 24 Конституции Российской Федерации, Федеральным законом от 27.07.2006 No
            152-ФЗ «О персональных данных», Правилами обработки персональных данных, размещенными на сайте
            https://cryptoenter.ru по адресу: https://cryptoenter.ru/personal-information (далее-Правила обработки ПДн),
            Я, субъект персональных данных, именуемый в дальнейшем Пользователь, отправляя информацию через формы обратной
            связи (далее, каждая из них и все в совокупности-Форма, Формы) при прохождении процедуры регистрации в целях
            использования сайта https://cryptoenter.ru и его сервисов (далее- Сайт, Сервис), и в дальнейшем при
            использовании Сайта и/или его Сервисов, выражаю полное, безоговорочное и однозначное Согласие на обработку
            моих персональных данных (далее-Согласие) на следующих условиях:
            1. Согласие выдано Обществу с ограниченной ответственностью «КРИПТОЭНТЕР» (ООО «КРИПТОЭНТЕР», ИНН 9703131162,
            ОГРН 1237700055713), расположенному по адресу: 123112, Г.Москва, вн.тер.г. Муниципальный Округ Пресненский,
            наб Пресненская, д. 8, стр. 1, Помещ. 1П, Ком. 41 (далее-Оператор).
            2. Согласие выдано на обработку персональных и иных данных, указанных Пользователем в Формах путем заполнения
            соответствующих текстовых полей и/или прикрепленных к Формам файлов, а именно следующих категорий:
            - фамилия, имя, отчество, адрес электронной почты (e-mail), номер телефона, адрес регистрации, другая
            аналогичная информация, сообщённая о себе Пользователем Сайта, на основании которой возможна его идентификация
            как субъекта персональных данных.
            - данных, которые автоматически передаются в процессе просмотра и при посещении страниц Сайта: IP адрес,
            информация из cookies, информация о браузере, время доступа, адрес посещаемой страницы, реферер (адрес
            предыдущей страницы);
            - статистики о моих IP-адресах.
            3. Согласие выдано на обработку персональных данных в целях:
            - исполнения соглашений по предоставлению доступа к Сайту, его Содержанию и/или Сервису, к функционалу
            Сервиса, для администрирования Сайта;
            - идентификации при регистрации на Сайте и/или при использовании Сервиса; - оказания услуг, обработки запросов
            и заявок;
            - установления обратной связи, включая направление уведомлений и запросов; - подтверждения полноты
            предоставленных персональных данных;
            - заключения договоров, осуществления взаиморасчетов;
            - сбора Оператором статистики;
            - улучшения качества работы Сайта и/или его Сервиса, удобства их
            использования и разработки новых сервисов и услуг;
            - проведения маркетинговых (рекламных) мероприятий, направления
            Оператором предложений и получения их Пользователем для продвижения на рынке услуг Оператора, в том числе,
            путем осуществления прямых контактов.
            Пользователь, настоящим, подтверждает, осведомлен и согласен, что для вышеуказанных целей, Оператор вправе
            собирать и использовать дополнительную информацию, связанную с Пользователем, получаемую в процессе доступа
            Пользователя к Сайту, его Содержанию и/или Сервису, или от третьих лиц, и включающую в себя данные о
            технических средствах (в том числе, мобильных устройствах) и способах технологического взаимодействия с Сайтом
            и/или его Сервисом (в т. ч. IP-адрес хоста, вид операционной системы Пользователя, тип браузера,
            географическое положение, данные о провайдере и иное), об активности
            Пользователя при использовании Сайта и/или его Сервиса, cookies, об информации об ошибках, выдаваемых
            Пользователю, о скачанных файлах, видео, инструментах, а также иные данные, получаемые установленными
            Правилами обработки ПДн способами; распоряжаться статистической информацией, связанной с функционированием
            Сайта и/или его Сервиса, а также информацией Пользователя для целей организации функционирования и технической
            поддержки Сайта и/или его Сервиса и исполнения условий законодательства Российской Федерации, и разработанных
            в соответствии с ним Правилами обработки ПДн.
            4. Согласие выдано на обработку персональных данных смешанным (автоматизированным и неавтоматизированным)
            способом.
            В процессе обработки персональных данных Оператор вправе осуществлять: сбор, запись, систематизацию,
            накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение,
            предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных Пользователя.
            5. Настоящим, Пользователь подтверждает, что:
            5.1. Ознакомлен и согласен с тем, что передача персональных данных Пользователя может осуществляться
            Оператором в объеме, необходимом для получения Пользователем доступа к Сайту, его Содержанию и/или его
            Сервису.
            5.2. Дает согласие на обработку своих персональных данных, указанных в пункте 2 настоящего согласия, лицам,
            указанным в пункте 1 настоящего согласия, а также подтверждает о разъяснении Оператором последствий
            непредоставления персональных данных, в случаях, когда предоставление таких данных является обязательным в
            соответствии с федеральным законом.
            5.3. Проинформирован о возможности отзыва согласия на основании положений Федерального закона от 27.07.2006 No
            152-ФЗ «О персональных данных» путем личного обращения или направления письменного обращения (в том числе в
            форме электронного документа, подписанного простой электронной подписью или усиленной квалифицированной
            электронной подписью), на имя лиц, указанных в пункте 1 Согласия, в том числе проинформирован о праве отозвать
            Согласие в целях прекращения обработки лицом, указанным в пункте 1 Согласия, как всех указанных в пункте 2
            Согласия персональных данных, так и отдельно биометрических персональных данных (если таковые будут запрошены
            Оператором и предоставлены Пользователем).
            5.4. Проинформирован, что лица, указанные в пункте 1 настоящего Согласия, вправе продолжать обработку
            персональных данных Пользователя после получения отзыва Согласия, а равно после истечения срока действия
            Согласия, при наличии оснований, предусмотренных частью 2 статьи 9 Федерального закона от 27.07.2006 No 152-ФЗ
            «О персональных данных».
            5.5. Дает согласие на получение рекламно-информационных материалов и ознакомлен Оператором о возможности и
            порядке совершения отказа от таковой.
            6. Согласие вступает в силу с момента его отправки и может быть отозвано Пользователем путем направления
            письменного заявления в произвольной форме в адрес Оператора по адресу электронной почты info@cryptoenter.ru
            либо иным способом, позволяющим однозначно определить факт его получения адресатом.
            7. Согласие действует в течение неопределенного срока до момента его отзыва Пользователем. Согласие прекращает
            свое действие с даты, указанной в заявлении Пользователя об отзыве Согласия на обработку ПДн, но не ранее
            даты, следующей за датой фактического получения Оператором отзыва Согласия.
            8. Датой и временем формирования, подтверждения и отправки Согласия
            прошу считать момент маркировки соответствующего поля (либо нажатия на

            соответствующую кнопку) в Форме и/или нажатие на кнопку отправки Формы на любой странице Сайта и/или его
            Сервиса.
            9. Руководствуясь ч. 4 ст. 9 Федерального закона от 27.07.2006 No 152-ФЗ «О персональных данных», направляю
            через личное мобильное устройство или персональный компьютер путем прикрепления к специальной форме или при
            заполнении соответствующих полей такой формы при прохождении процедуры регистрации на Сайте свои персональные
            данные.
            10. Пользователь проинформирован, что ответственность перед ним за действия лица, обрабатывающего его
            персональные данные по поручению Оператора, несет Оператор, выдавший соответствующее поручение (согласие).
          </template>
          <template v-else>
            In accordance with Articles 23, 24 of the Constitution of the Russian Federation, Federal Law of July 27, 2006 No.
            152-FZ “On Personal Data”, Rules for the processing of personal data posted on the website
            https://cryptoenter.ru at: https://cryptoenter.ru/personal-information (hereinafter referred to as the PD Processing Rules),
            I, the subject of personal data, hereinafter referred to as the User, submitting information through the feedback forms
            communications (hereinafter, each of them and all together - Form, Forms) when completing the registration procedure for the purpose of
            use of the site https://cryptoenter.ru and its services (hereinafter referred to as the Site, Service), and in the future when
            using the Site and/or its Services, I express full, unconditional and unequivocal Consent to the processing
            my personal data (hereinafter referred to as Consent) on the following conditions:
            1. Consent was issued to the Limited Liability Company "KRYPTOENTER" (LLC "KRYPTOENTER", INN 9703131162,
            OGRN 1237700055713), located at the address: 123112, Moscow, vn.ter.g. Municipal District Presnensky,
            Presnenskaya embankment, 8, building 1, premises. 1P, Com. 41 (hereinafter referred to as the Operator).
            2. Consent was given to the processing of personal and other data specified by the User in the Forms by filling out
            corresponding text fields and/or files attached to Forms, namely the following categories:
            - last name, first name, patronymic, email address (e-mail), telephone number, registration address, other
            similar information provided by the Site User about himself, on the basis of which his identification is possible
            as a subject of personal data.
            - data that is automatically transmitted during browsing and when visiting pages of the Site: IP address,
            information from cookies, browser information, access time, address of the page being visited, referrer (address
            previous page);
            - statistics about my IP addresses.
            3. Consent was given to the processing of personal data for the purposes of:
            - execution of agreements for providing access to the Site, its Content and/or Service, and functionality
            Service for administering the Site;
            - identification when registering on the Site and/or using the Service; - provision of services, processing requests
            and applications;
            - establishing feedback, including sending notifications and requests; - confirmation of completeness
            provided personal data;
            - concluding contracts, carrying out mutual settlements;
            - collection of statistics by the Operator;
            - improving the quality of the Site and/or its Service, their convenience
            use and development of new services;
            - carrying out marketing (advertising) events, directions
            by the Operator of offers and their receipt by the User for promotion in the market of the Operator’s services, including,
            through direct contacts.
            The User hereby confirms, is aware and agrees that for the above purposes, the Operator has the right
            collect and use additional information related to the User obtained during the access process
            User to the Site, its Content and/or Service, or from third parties, and including data about
            technical means (including mobile devices) and methods of technological interaction with the Site
            and/or its Service (including host IP address, type of User’s operating system, browser type,
            geographic location, provider data, etc.), activity
            The User when using the Site and/or its Service, cookies, information about errors generated
            To the user about downloaded files, videos, tools, as well as other data received by installed
            Rules for processing personal data by methods; manage statistical information related to the functioning
            of the Site and/or its Service, as well as User information for the purposes of organizing the operation and technical
            support of the Site and/or its Service and compliance with the conditions of the legislation of the Russian Federation, and developed
            in accordance with the PD Processing Rules.
            4. Consent was issued for mixed processing of personal data (automated and non-automated)
            way.
            In the process of processing personal data, the Operator has the right to carry out: collection, recording, systematization,
            accumulation, storage, clarification (updating, changing), retrieval, use, transmission (distribution,
            provision, access), depersonalization, blocking, deletion, destruction of the User’s personal data.
            5. The User hereby confirms that:
            5.1. I am aware of and agree that the transfer of the User’s personal data may be carried out
            By the operator to the extent necessary for the floor teaching the User access to the Site, its Content and/or its
            Service.
            5.2. Gives consent to the processing of his personal data specified in paragraph 2 of this consent to persons
            specified in paragraph 1 of this consent, and also confirms the Operator’s explanation of the consequences
            failure to provide personal data, in cases where the provision of such data is mandatory in
            in accordance with federal law.
            5.3. Informed of the possibility of revoking consent on the basis of the provisions of Federal Law dated July 27, 2006 No.
            152-FZ “On Personal Data” by personal request or sending a written request (including
            form of an electronic document signed with a simple electronic signature or an enhanced qualified
            electronic signature), addressed to the persons specified in paragraph 1 of the Consent, including informed of the right to withdraw
            Consent for the purpose of stopping processing by the person specified in paragraph 1 of the Consent, as well as all those specified in paragraph 2
            Consent of personal data and separately biometric personal data (if any are requested
            by the Operator and provided by the User).
            5.4. Informed that the persons specified in paragraph 1 of this Consent have the right to continue processing
            personal data of the User after receiving the withdrawal of Consent, as well as after expiration
            Consent, if there are grounds provided for in Part 2 of Article 9 of the Federal Law of July 27, 2006 No. 152-FZ
            “About personal data.”
            5.5. Gives consent to receive advertising and information materials and is informed by the Operator about the possibility and
            procedure for making a refusal thereof.
            6. Consent comes into force from the moment it is sent and can be revoked by the User by sending
            a written statement in any form to the Operator at the email address info@cryptoenter.ru
            or in another way that makes it possible to unambiguously determine the fact of its receipt by the addressee.
            7. The consent is valid for an indefinite period until it is withdrawn by the User. Consent terminates
            effective from the date specified in the User’s application for revocation of Consent to PD processing, but not earlier
            date following the date of actual receipt by the Operator of the revocation of Consent.
            8. Date and time of formation, confirmation and sending of the Consent
            please consider the moment of marking the corresponding field (or clicking on

            corresponding button) in the Form and/or clicking on the button to submit the Form on any page of the Site and/or its
            Service.
            9. Guided by Part 4 of Art. 9 of the Federal Law of July 27, 2006 No. 152-FZ “On Personal Data”, I am sending
            through a personal mobile device or personal computer by attaching to a special form or when
            filling out the appropriate fields of this form when completing the registration procedure on the Site with your personal
            data.
            10. The user is informed that he is liable for the actions of the person processing it
            personal data on behalf of the Operator is borne by the Operator who issued the corresponding order (consent).
          </template>
        </p>
      </div>
      <div class="Registration-Line aml">
        <input
          id="aml"
          class=""
          type="checkbox"
          name="aml"
          :checked="amlKey"
        >
        <label
          class="Registration-Checkbox"
          for="aml"
        /><br>
        <p class="aml_margin">
          {{ $t('regp_pers_data_label') }}
        </p>
      </div>
      <input
        class="Global_Button Global_Button_FontSize_S Global_Button_Color_White Global_Button_Rounding_Main"
        type="submit"
        :value="$t('reg_send_email_btn')"
      >
    </form>

    <div
      v-else
      class="Registration-LoginTop ce-registration__user"
    >
      <ce-text-field
        v-model="userId"
        :label="$t('regp_login_label')"
        type="text"
        disabled
      />
      <ce-text-field
        id="password"
        v-model="password"
        :type="showPassword ? 'text' : 'password'"
        :placeholder="$t('regp_password_placeholder')"
        :label="$t('regp_password_label')"
        @focus="resetValid"
        @blur="validatePassword"
      >
        <template #append-label>
          <i class="Icon_Info info-icon" />
          <div class="Form-HelpModal help-modal">
            {{ $t('reg_password_rule') }}
          </div>
        </template>
        <template #append-input>
          <span @click="showPassword = !showPassword"><i class="Icon_Eye" /></span>
        </template>
      </ce-text-field>
      <ce-text-field
        id="passwordRep"
        v-model="passwordRep"
        :type="showPasswordRepeat ? 'text' : 'password'"
        :placeholder="$t('regp_repeat_password_placeholder')"
        :label="$t('regp_repeat_password_label')"
        @blur="validateRepPassword"
        @focus="resetValid"
      >
        <template #append-input>
          <span @click="showPasswordRepeat = !showPasswordRepeat"><i class="Icon_Eye" /></span>
        </template>
      </ce-text-field>
      <template v-if="key">
        <ce-textarea
          id="key"
          v-model="key"
          class="ce-registration__pem-text"
          name="key"
        />
        <input
          class="Global_Button Global_Button_FontSize_S Global_Button_Color_White Global_Button_Rounding_Main Registration-LoginTop"
          type="submit"
          :value="$t('regp_save_and_login_btn')"
          @click="saveAndLogin"
        >
      </template>
      <input
        v-else
        class="Global_Button Global_Button_FontSize_S Global_Button_Color_White Global_Button_Rounding_Main Registration-LoginTop"
        type="submit"
        :value="$t('regp_registration_btn')"
        @click="singUp"
      >
      <div class="alert alert-warning">
        {{ $t('regp_cannot_restored_1') }}<br>{{ $t('regp_cannot_restored_2') }}
      </div>
    </div>
    <div class="Registration-MobileLock">
      <p>{{ $t('regp_for_completing') }}</p>
    </div>
  </div>
</template>

<script>
import { saveAs } from 'file-saver'
import { VueRecaptcha } from 'vue-recaptcha'
import { SessionStorageKeys } from '@/models/StorageKeys'
import { mapState } from 'vuex'
import SavePem from '@/Plugins/SavePem'
import { NativeBiometric } from 'capacitor-native-biometric'
import CeTextField from '@/components/common/CeTextField.vue'
import CeTextarea from '@/components/common/CeTextarea.vue'

export default {
  name: 'Login',
  components: {
    CeTextarea,
    CeTextField,
    VueRecaptcha
  },
  metaInfo: {
    title: 'Registration'
  },
  computed: {
    ...mapState(['isProductionHostname'])
  },
  data () {
    return {
      userId: '',
      email: '',
      promo: '',
      password: '',
      passwordRep: '',
      key: '',
      generalCheckbox: false,
      termKey: false,
      amlKey: false,
      captchaResponse: '',
      step: true,
      autoPromo: false,
      showPassword: false,
      showPasswordRepeat: false
    }
  },
  mounted () {
    if (this.$route.query.code) {
      this.step = false
      this.userId = this.$route.query.userId
      this.email = this.$route.query.email
    }
    this.promo = this.$route.query.reff ?? sessionStorage.getItem(SessionStorageKeys.promocode)
    this.autoPromo = Boolean(this.promo)
  },
  methods: {
    getCurrentBrowserLang () {
      if (!navigator?.language) return 'en'
      return navigator.language.split('-')[0]
    },
    recapchaver (response) {
      // console.log(response)
      this.captchaResponse = response
    },
    showHidePassword () {
      let inputPass = document.getElementById('password')
      if (inputPass.getAttribute('type') === 'password') {
        inputPass?.setAttribute('type', 'text')
      } else {
        inputPass?.setAttribute('type', 'password')
      }
    },
    showHideRepPassword () {
      let inputPass = document.getElementById('passwordRep')
      if (inputPass.getAttribute('type') === 'password') {
        inputPass?.setAttribute('type', 'text')
      } else {
        inputPass?.setAttribute('type', 'password')
      }
    },
    resetValid (event) {
      event.target.classList.remove('ErrorInput')
      event.target.classList.remove('SuccessInput')
    },
    validLogin (event) {
      if (this.$NewValid({
        typeValidation: 'email',
        dataForValid: { email: this.email }
      })) {
        event.target.classList.remove('ErrorInput')
        event.target.classList.add('SuccessInput')
      } else {
        event.target.classList.remove('SuccessInput')
        event.target.classList.add('ErrorInput')
      }
    },
    validateEmail (event) {
      if (this.$NewValid({
        typeValidation: 'email',
        dataForValid: { email: this.email }
      })) {
        event.target.classList.remove('ErrorInput')
        event.target.classList.add('SuccessInput')
      } else {
        event.target.classList.remove('SuccessInput')
        event.target.classList.add('ErrorInput')
      }
    },
    validatePassword (event) {
      if (this.$NewValid({
        typeValidation: 'password',
        dataForValid: { password: this.password }
      })) {
        event.target.classList.remove('ErrorInput')
        event.target.classList.add('SuccessInput')
        if (this.passwordRep) {
          if (this.$NewValid({
            typeValidation: 'repPassword',
            dataForValid: { password: this.password, repPassword: this.passwordRep }
          })) {
            document.getElementById('passwordRep').classList.add('SuccessInput')
            document.getElementById('passwordRep').classList.remove('ErrorInput')
          } else {
            document.getElementById('passwordRep').classList.remove('SuccessInput')
            document.getElementById('passwordRep').classList.add('ErrorInput')
            this.$notify({
              title: this.$t('error_title'),
              text: this.$selectMessageText('En', 'passwordMismatch'),
              type: 'error'
            })
          }
        }
      } else {
        event.target.classList.remove('SuccessInput')
        event.target.classList.add('ErrorInput')
        this.$notify({
          title: this.$t('error_title'),
          text: this.$selectMessageText('En', 'passwordDoesNotMatchRequirements'),
          type: 'error'
        })
      }
    },
    validateRepPassword (event) {
      if (this.$NewValid({
        typeValidation: 'repPassword',
        dataForValid: { password: this.password, repPassword: this.passwordRep }
      })) {
        event.target.classList.remove('ErrorInput')
        event.target.classList.add('SuccessInput')
      } else {
        event.target.classList.remove('SuccessInput')
        event.target.classList.add('ErrorInput')
        this.$notify({
          title: this.$t('error_title'),
          text: this.$selectMessageText('En', 'passwordDoesNotMatchRequirements'),
          type: 'error'
        })
      }
    },
    sendEmail () {
      let errorElements = document.querySelector('.ErrorInput')
      if (!errorElements) {
        if (this.email) {
          if (this.captchaResponse) {
            if (!document.getElementById('general-checkbox').checked) {
              return this.$notify({
                title: this.$t('error_title'),
                text: this.$selectMessageText('En', 'termOfUseNotVerified'),
                type: 'error'
              })
            }
            if (document.getElementById('term').checked) {
              if (document.getElementById('aml').checked) {
                this.$store.commit('SetLoaderFlag', true)
                this.$store.dispatch('sendEmail', {
                  email: this.$EnteringUser(this.email),
                  promo_code: this.promo,
                  captcha: this.captchaResponse,
                  language: this.getCurrentBrowserLang()
                })
                    .then(response => {
                      if (response.data.status === 200) {
                        this.$router.push({ name: 'successEmail', params: { email: this.email } })
                      } else {
                        this.$notify({
                          title: this.$t('error_title'),
                          text: this.$selectMessageText('En', 'emailNotSent'),
                          type: 'error'
                        })
                      }
                    })
                    .catch(() => {
                      this.$notify({
                        title: this.$t('error_title'),
                        text: this.$selectMessageText('En', 'emailNotSent'),
                        type: 'error'
                      })
                    })
                    .finally(() => {
                      this.$store.commit('SetLoaderFlag', false)
                    })
              } else {
                // this.$notify({
                //   title: this.$t('error_title'),
                //   text: this.$selectMessageText('En', 'amlNotVerified'),
                //   type: 'error'
                // })
                this.$notify({
                  title: this.$t('error_title'),
                  text: this.$selectMessageText('En', 'termOfUseNotVerified'),
                  type: 'error'
                })
              }
            } else {
              this.$notify({
                title: this.$t('error_title'),
                text: this.$selectMessageText('En', 'termOfUseNotVerified'),
                type: 'error'
              })
            }
          } else {
            this.$notify({
              title: this.$t('error_title'),
              text: this.$selectMessageText('En', 'confirmRobot'),
              type: 'error'
            })
          }
        } else {
          this.$notify({
            title: this.$t('error_title'),
            text: this.$selectMessageText('En', 'loginNotFiled'),
            type: 'error'
          })
        }
      } else {
        this.$notify({
          title: this.$t('error_title'),
          text: this.$selectMessageText('En', 'loginNotMail'),
          type: 'error'
        })
      }
    },
    singUp () {
      let dataForSend = {
        username: this.userId,
        password: this.password,
        code: this.$route.query.code,
        userId: this.$route.query.userId
      }
      if (!document.querySelector('.ErrorInput')) {
        if (this.password && this.passwordRep) {
          if (dataForSend.password) {
            this.$store.commit('SetLoaderFlag', true)
            this.$store.dispatch('signUp', dataForSend)
                .then(privatePEM => {
                  if (privatePEM) {
                    this.key = privatePEM
                    NativeBiometric.isAvailable().then(result => {
                      if (result.isAvailable) {
                        this.$store.commit('setBiometricData', {
                          password: this.password,
                          key: privatePEM
                        })
                        this.$store.commit('setShowUseBiometricPopup', true)
                      }
                    })
                  } else {
                    this.$notify({
                      title: this.$t('error_title'),
                      text: this.$selectMessageText('En', 'operationFailed'),
                      type: 'error'
                    })
                  }
                })
                .catch(() => {
                  this.$notify({
                    title: this.$t('error_title'),
                    text: this.$selectMessageText('En', 'operationFailed'),
                    type: 'error'
                  })
                })
                .finally(() => {
                  this.$store.commit('SetLoaderFlag', false)
                })
          } else {
            this.$notify({
              title: this.$t('error_title'),
              text: this.$selectMessageText('En', 'passwordNotEntered'),
              type: 'error'
            })
          }
        } else {
          this.$notify({
            title: this.$t('error_title'),
            text: this.$selectMessageText('En', 'dataNotCorrectly'),
            type: 'error'
          })
        }
      } else {
        this.$notify({
          title: this.$t('error_title'),
          text: this.$selectMessageText('En', 'dataNotCorrectly'),
          type: 'error'
        })
      }
    },
    async saveAndLogin () {
      // let BB = self.Blob
      let BB = Blob
      let key = this.key
      let name = this.email
      let userId = this.userId
      saveAs(new BB([key], { type: 'text/plain;charset=utf8' }),
          (`${userId}(${name}).pem`)
      )
      if (isAndroidApp) {
        await SavePem?.save({
          pemString: this.key,
          userId: this.userId,
          userEmail: this.email
        })
      }
      this.$store.commit('SetLoaderFlag', true)
      this.$store.dispatch('singIn', { login: this.userId, password: this.password, key: this.key })
          .catch(() => {
            this.$store.commit('SetLoaderFlag', false)
            this.$notify({
              title: this.$t('error_title'),
              text: this.$selectMessageText('En', 'loginFailed'),
              type: 'error'
            })
          })
    }
  }
}
</script>

<style lang="stylus">
.Registration
  &-MobileLock
    display: none

  &_FontWidth
    font-weight 400

  &_Margin_Key
    margin-top 20px

  &_MarginCommon
    &_Top
      margin-top 14px

    &_Bottom
      margin-bottom 18px

  &-LoginTop
    margin-top: 40px

  &-InputAccount
    background: #ffffff
    font-family 'Geometria', sans-serif
    width: 100%
    padding: 10px
    font-size: 18px
    margin-bottom: 20px
    font-weight: 400;
    outline: none;
    border-width 0
    text-align: left

  &-Checkbox
    font-family 'Geometria', sans-serif
    padding: 0 0 0 2px
    text-align: left
    font-size: 14px
    font-weight: 500
    color #5F5F5F

  &-Line
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 14px
    justify-content: flex-start;

  &-Input
    &_File
      display none

  &-RegistrationActiv
    font-family 'Montserrat', sans-serif
    text-decoration: none
    font-style: normal;
    font-weight: bold;
    font-size: 20px
    color #ffc600
    margin-left 10px
    border-top 3px solid #fff
    border-bottom 3px solid #FFC600

    &:hover
      color #ffc600

  &-LoginActiv
    font-family 'Montserrat', sans-serif
    text-decoration: none
    font-style: normal;
    font-weight: bold;
    margin-right 10px
    font-size: 20px
    color #bFbFbF

    &:hover
      color #ffc600

.alert-warning
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba

.alert
  position: relative;
  padding: .75rem 1.25rem;
  margin-top: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;

</style>

<style lang="scss" scoped>
.ce-registration {
  &__user {
    display: flex;
    flex-direction: column;
    gap: 12px;
    &-password {
      &-label {
        display: inline-flex;
        position: relative;
        gap: 5px;

        label {
          width: min-content;
        }

        .info-icon {
          transform: translateY(-25%);
        }

        .help-modal {
          text-align: left;
        }
      }
    }
  }
  &__pem-text {
    :deep(.ce-textarea__input) {
      font-size: 12px;
      line-height: 100%;
    }
  }

  &-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .underway {
    height: 373px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    span {
      position: absolute;
      bottom: 0;
    }
  }
}
</style>
