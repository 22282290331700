<template>
  <div class="SuccessRegistration">
    <img
      class="SuccessEmail-LogoImg"
      src="../assets/images/LogoCE306x60.png"
      alt="Logo"
      width="250"
    >
    <h3 class="SuccessEmail-Header">
      {{ $t('reg_success_thx') }}
    </h3>
    <p class="SuccessEmail-Text">
      {{ $t('reg_success_few_seconds') }}
    </p>
    <router-link
      class="SuccessRegistration_Button"
      to="/lk/myAccount"
    >
      ({{ timer }}) {{ $t('reg_lets_go') }}
    </router-link>
  </div>
</template>

<script>
import ROUTER from '@/router'
import STORE from '@/store'
export default {
  metaInfo: {
    title: 'Success'
  },
  data () {
    return {
      timer: 10,
      timeoutIdx: null
    }
  },
  created () {
    STORE.commit('SetLoaderFlag', false)
    this.timerFunck()
  },
  methods: {
    timerFunck: function () {
      if (this.timer === 0) {
        if (ROUTER.history?.current.path !== '/lk/myAccount') {
          STORE.commit('SetLoaderFlag', true)
          ROUTER.push('/lk/myAccount')
        }
      } else {
        this.timer--
        this.timeoutIdx = setTimeout(this.timerFunck, 1000)
      }
    }
  },
  beforeUnmount () {
    clearTimeout(this.timeoutIdx)
  }
}
</script>

<style lang="stylus">
  .SuccessRegistration
    margin: auto
    max-width: 500px;
    margin-top: 10%
    margin-bottom: 15%
    background: #ffffff
    padding: 50px 40px 70px
    border-radius: 4px
    text-align: center
    box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.2)
    &_Button
      font-family 'Montserrat', sans-serif
      font-weight: bold
      cursor pointer
      padding 8px 15%
      border 0px
      font-size: 14px
      color #ffffff
      background-color #ffc600
      border-radius 30px
      &:hover
        color #ffffff
        background-color #59D762
      &:active
        color #ffffff
        background-color #ffc600
    &-LogoImg
      margin-top 20px
      max-width 100%
    &-Header
      margin-top 30px
      color #5F5F5F
      font-weight: bold;
      font-size: 20px
      font-family 'Montserrat', sans-serif
    &-Text
      font-family 'Geometria', sans-serif
      margin-top 14 px
      margin-bottom 0px
  @media screen and (max-width: 414px)
    .SuccessRegistration
      margin 20px
      margin-top: 15%
      margin-bottom: 20%
    .SuccessEmail
      padding: 50px 30px
      max-width: 92%;
      margin-top: 20%;
      margin-bottom: 30%;
      &-LogoImg
        width: 96%
  @media screen and (max-width: 375px)
    .SuccessEmail
      padding: 50px 30px
      max-width: 92%;
      margin-top: 20%;
      margin-bottom: 30%;
      &-LogoImg
        max-width: 96%;
</style>
